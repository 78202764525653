<template>
  <v-card class="pa-4">
    <div
      v-if="result == null"
      class="body-1 grey--text"
    >
      <v-progress-circular
        class="ma-10"
        size="70"
        width="7"
        color="grey lighten-2"
        indeterminate
      />
      Fetching Measurements
    </div>
    <div v-else>
      <v-data-table
        :headers="result.tableHeaders"
        :items="result.tableItems"
        :items-per-page="15"
        class="elevation-1"
        dense
      >
        <template #[`item.eventAt`]="{ item }">
          <span class="event-at">{{ new Date(item.eventAt) | formatDateDetail }}</span>
        </template>

        <template #[`item.subject`]="{ item }">
          <v-icon
            v-if="item.subject == true"
            size="small"
            class="pa-1 round-icon true-feedback-bg"
            color="white"
            title="Success"
          >
            mdi-check
          </v-icon>
          <v-icon
            v-if="item.subject == false"
            size="small"
            class="pa-1 round-icon false-feedback-bg"
            color="white"
            title="Fail"
          >
            mdi-close
          </v-icon>

          <v-icon
            v-if="item.subject == null"
            size="small"
            class="pa-1 round-icon missing-feedback-bg"
            color="white"
            title="Missing"
          >
            mdi-help
          </v-icon>
        </template>

        <template #[`item.supporter`]="{ item }">
          <v-icon
            v-if="item.supporter == true"
            size="small"
            class="pa-1 round-icon true-feedback-bg"
            color="white"
            title="Success"
          >
            mdi-check
          </v-icon>
          <v-icon
            v-if="item.supporter == false"
            size="small"
            class="pa-1 round-icon false-feedback-bg"
            color="white"
            title="Fail"
          >
            mdi-close
          </v-icon>

          <v-icon
            v-if="item.supporter == null"
            size="small"
            class="pa-1 round-icon missing-feedback-bg"
            color="white"
            title="Missing"
          >
            mdi-help
          </v-icon>
        </template>

        <!-- Support only 3 images -->
        <template #[`item.picture_0`]="{ item }">
          <ImageFromReference
            v-if="item.picture_0 !== ''"
            :max-width="150"
            :max-height="150"
            :reference="item.picture_0"
          />
        </template>
        <template #[`item.picture_1`]="{ item }">
          <ImageFromReference
            v-if="item.picture_1 !== ''"
            :max-width="150"
            :max-height="150"
            :reference="item.picture_1"
          />
        </template>
        <template #[`item.picture_2`]="{ item }">
          <ImageFromReference
            v-if="item.picture_2 !== ''"
            :max-width="150"
            :max-height="150"
            :reference="item.picture_2"
          />
        </template>
      </v-data-table>

      <v-card
        class="mt-4"
        elevation="1"
        outlined
      >
        <v-card
          class="d-flex flex-wrap"
          flat
        >
          <SparkLineChart
            v-for="qm in quantitativeMeasurements"
            :key="qm.id"
            :title="qm.title"
            :data="dataById(qm.id)"
            :role="qm.role"
            :data-type="qm.type"
            :data-style="qm.style"
          />
        </v-card>
        <div class="caption grey--text text-center">
          Measurements Trends
        </div>
      </v-card>
    </div>
  </v-card>
</template>


<script>
import { functions } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';
import SparkLineChart from '@/components/programs/SparkLineChart.vue';

const callableStatisticsMeasurementsData = functions.httpsCallable('callableStatisticsMeasurementsData');

export default {
  name: 'ProgramMeasurements',
  components: {
    SparkLineChart,
    ImageFromReference,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      result: null,
    };
  },
  computed: {
    quantitativeMeasurements() {
      return this.result.measurements.filter((m) => m.type === 'number' && m.style !== 'yes_no');
    },
  },
  created() {
    this.$store.commit('setLoading', true);
    callableStatisticsMeasurementsData({
      programId: this.programId,
    }).then((result) => {
      // Dirty fix because of Vuetify data table limitation
      // Loop through table, change picture types id to picture_<number>
      /*eslint-disable */     
      const pictureIds = [];
      for (const item of result.data.measurements) {
        if (item.type === 'picture') pictureIds.push(item.id);
      }

      for (const item of result.data.tableHeaders) {
        const idPos = pictureIds.indexOf(item.value);
        if (idPos > -1) item.value = 'picture_' + idPos;
      }

      for (const row of result.data.tableItems) {
        for (const [key, value] of Object.entries(row)) {
          const idPos = pictureIds.indexOf(key);
          if (idPos > -1) row['picture_' + idPos] = value; // Add new column with picture_X name
        }
      }
      /* eslint-enable */

      this.result = result.data;
      this.$store.commit('setLoading', false);
    });
  },
  methods: {
    dataById(id) {
      const data = [];
      const items = this.result.tableItems; // Shorthand
      for (let i = items.length - 1; i > 0; i -= 1) {
        if (id in items[i]) {
          data.push(parseFloat(items[i][id]));
        }
      }
      return data;
    },
  },

};
</script>

<style>
  /* Not scoped. Used in program event list */
  .missing-feedback-bg {
    background-color: gray;
  }
  .true-feedback-bg {
    background-color: #28DF99;
  }
  .false-feedback-bg {
    background-color: #223959;
  }
  .round-icon {
    border-radius: 20px;
  }
  .event-at{
    white-space: nowrap;
  }
</style>
