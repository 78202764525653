<template>
  <v-card
    elevation="0"
    color="grey lighten-4"
  >
    <v-card-text>
      <div class="subtitle-1 text-center">
        {{ title }}
      </div>
      <div class="headline text-center">
        {{ fact }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'FactCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    fact: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>

