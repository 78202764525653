<template>
  <div class="video-container">
    <video
      v-if="youtubeId == ''"
      id="video"
      height="600"
      controls
    >
      <source
        v-if="ready"
        autoplay
        :src="url"
        type="video/mp4"
      >
    </video>

    <iframe
      v-if="youtubeId != ''"
      width="800"
      height="600"
      :src="youtubeEmbedUrl"
      frameborder="0"
      allow="autoplay"
    />
  </div>
</template>


<script>
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  name: 'VideoFromReference',
  props: {
    reference: {
      type: String,
      required: true,
    },
    youtubeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      url: '',
      ready: false,
    };
  },
  computed: {
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.youtubeId}?autoplay=1&enablejsapi=1`;
    },
  },
  created() {
    if (this.youtubeId !== '') {
      this.url = this.reference;
      this.ready = true;
    } else {
      firebase.storage().ref(this.reference).getDownloadURL().then((url) => {
        this.url = url;
        this.ready = true;
      });
    }
  },
  methods: {

  },

};
</script>

<style scoped>
.video-container {
  padding: 5px;
  text-align: center;
}
</style>
