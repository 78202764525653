<template>
  <div class="text-center pa-4">
    <v-dialog 
      v-model="dialog" 
      max-width="800"
      persistent
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          :disabled="loading"
          :loading="loading" 
          v-bind="attrs" 
          v-on="on"
          @click="openDialog"
        >
          Start
        </v-btn>
      </template>

      <v-card>
        <v-card-title 
          v-if="loading"
          class="headline primary" 
          style="color: white"
        >
          <v-icon 
            class="pa-2 round-icon"
            color="white"
          >
            mdi-run-fast
          </v-icon>
          Your program is setting up...
        </v-card-title>

        <v-card-title 
          v-if="!loading"
          class="headline primary" 
          style="color: white"
        >
          <v-icon 
            class="pa-2 round-icon"
            color="white"
          >
            mdi-run-fast
          </v-icon>
          Your program is running!
        </v-card-title>

        <v-divider class="mb-4" />

        <v-card-text>
          <v-row 
            v-if="result"
          >
            <v-col
              md="8"
              offset-md="2"
            >
              <p class="text--primary">
                Below are the necessary invitation codes for each role.
              </p>
              <div
                v-if="loading"
                class="w-100 pa-4 d-flex align-items-center justify-content-center"
                height="250"
                outlined
              >
                <v-card-text
                  style="text-align: center;"
                >
                  <v-progress-circular indeterminate />
                </v-card-text>
              </div>
              <v-card
                v-if="!loading"
                class="mt-4 mb-4 pa-4"
                height="250"
                outlined
              >
                <v-simple-table
                  v-if="!loading"
                >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Role
                      </th>
                      <th class="text-left">
                        Code
                      </th>
                      <th class="text-left">
                        Email
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(invitation, index) in result.invitations"
                      :key="index"
                    >
                      <td>{{ invitation.role }}</td>
                      <td>{{ invitation.code }}</td>
                      <td>
                        <div
                          v-if="!invitation.email"
                          class="overline grey--text"
                        >
                          Not provided
                        </div>
                        <div v-else>
                          Invitation sent to {{ invitation.email }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <p class="text--secondary">
                If you have not provided an email, 
                you need to write these codes down and give them to the appropriate people.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="loading"
            @click="redirectToProgram(result.programId)"
          >
            Open program
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.$emit('dialogOpened');
    },
    redirectToProgram(programId) {
      this.$router.push({ name: 'ProgramsEdit', params: { programId } });
    },
  },
};
</script>
