import firebase from 'firebase/app';
import 'firebase/auth';

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import Login from '@/views/Login.vue';
import UpdatePassword from '@/views/UpdatePassword.vue';
import Home from '@/views/Home.vue';
import Templates from '@/views/templates/List.vue';
import TemplatesEdit from '@/views/templates/Edit.vue';

import Programs from '@/views/programs/List.vue';
import ProgramsEdit from '@/views/programs/Edit.vue';
import ProgramsStart from '@/views/programs/Start.vue';

import ProgramsStatistics from '@/views/programs/Statistics.vue';
import ProgramsEvents from '@/views/programs/events/List.vue';
import ProgramsTransactions from '@/views/programs/transactions/List.vue';
import ProgramsCodes from '@/views/programs/codes/List.vue';

import ProgramsStore from '@/views/programs/store/List.vue';
import ProgramsStoreItemEdit from '@/views/programs/store/Edit.vue';

import Rewards from '@/views/rewards/List.vue';
import RewardsEdit from '@/views/rewards/Edit.vue';

import Achievements from '@/views/achievements/List.vue';
import AchievementsEdit from '@/views/achievements/Edit.vue';

import Users from '@/views/users/List.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/update-password',
    name: 'updatePassword',
    component: UpdatePassword,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: Templates,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/templates/:templateId',
    name: 'templatesEdit',
    props: true,
    component: TemplatesEdit,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs',
    name: 'programs',
    component: Programs,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId',
    name: 'ProgramsEdit',
    props: true,
    component: ProgramsEdit,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/statistics',
    name: 'ProgramsStatistics',
    props: true,
    component: ProgramsStatistics,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/events',
    name: 'ProgramsEvents',
    props: true,
    component: ProgramsEvents,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/transactions',
    name: 'ProgramsTransactions',
    props: true,
    component: ProgramsTransactions,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/store',
    name: 'ProgramStore',
    props: true,
    component: ProgramsStore,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/store/:storeItemId',
    name: 'ProgramStoreItem',
    props: true,
    component: ProgramsStoreItemEdit,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/:programId/codes',
    name: 'ProgramsCodes',
    props: true,
    component: ProgramsCodes,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/programs/start/:templateId',
    name: 'programStart',
    props: true,
    component: ProgramsStart,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: Achievements,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/achievements/:achievementId',
    name: 'achievementsEdit',
    props: true,
    component: AchievementsEdit,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: Rewards,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/rewards/:rewardId',
    name: 'rewardsEdit',
    props: true,
    component: RewardsEdit,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      loginRequired: true,
    },
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Route guard!
router.beforeEach((to, from, next) => {
  if (!to.meta.loginRequired && !to.meta.staffRequired) {
    // Route is public, don't check for authentication
    next();
  } else { // Route is protected, if authenticated, proceed. Else, login
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (store.getters.user == null) {
          store.dispatch('resetUser', user);
        }

        if (to.meta.staffRequired && !store.getters.isStaff) {
          next('/login'); // User is not staff
        }

        next(); // User is logged in
      } else {
        next('/login');
      }
    });
  }
});

export default router;
