<template>
  <v-card
    class="ma-2"
    width="340"
    flat
    outlined
  >
    <div class="overline text-center">
      {{ title }}
    </div>
    <v-sparkline
      :value="data"
      :gradient="normalGradient"
      smooth="5"
      line-width="3"
      height="200"
    />
    <div
      v-if="data.length == 0"
      class="caption red--text text-center"
    >
      No measurements
    </div>
    <div class="caption text-center">
      Reported by {{ role }}
    </div>
  </v-card>
</template>


<script>
import LineChart from '@/components/LineChart.vue';

export default {
  name: 'ProgramMeasurementChart',
  components: {

  },
  props: {
    title: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
      default: '',
    },
    dataStyle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {

    };
  },

  methods: {

  },

};
</script>
