<template>
  <div>
    <v-chip-group
      v-model="selectedTags"
      multiple
      column
      active-class="primary"
    >
      <v-chip
        v-for="tag in tags"
        :key="tag"
        class="tag-chip"
      >
        {{ tag | capitalize }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>

export default {
  name: 'TagList',
  props: {
    tags: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedTags: [],
    };
  },

  watch: {
    selectedTags: {
      handler(val, oldVal) {
        if (this.selectedTags.length >= 10) {
          alert('Must be fewer than 10');
          return;
        }

        const selectedTags = this.tags.filter((tag, i) => this.selectedTags.includes(i));
        this.$emit('selected-tags', selectedTags);
      },
    },
  },

};
</script>

<style scoped>
.tag-chip {
    user-select: none;
}
</style>
