<template>
  <v-hover
    v-if="card !== null"
    v-slot="{ hover }"
  >
    <v-card
      class="mb-2 pa-2"
      :class="{ 'empty-card': isEmpty, 'cursor-pointer': !selected,
                'selected-card': selected, 'totally-hidden': isTotallyHidden }"
      :style="{
        backgroundImage: (style !== null) ?
          (style.bgImage == null) ?
            'linear-gradient(45deg,' + style.bgColor1 +','+ style.bgColor2 +')' :
            'url(/static/backgrounds/' + style.bgImage + ')' : null,
        backgroundSize: (style !== null && style.bgImage != null) ? 'cover' : null,
        color: (style !== null) ? style.fgColor : null
      }"
      :hover="hover"
      @click.native="selectCard"
    >
      <div
        v-if="card.title.length > 0"
        class="text-center mb-2"
        :class="{ 'headline': card.title.length <= 25, 'subtitle-1': card.title.length > 25 }"
      >
        {{ card.title }}
      </div>

      <!-- eslint-disable-next-line -->
    <div class="body-2" v-if="card.text.length > 0" v-html="compiledMarkdown"></div>


      <div v-if="card.text.length == 0 && readyMedia.length == 1">
        <ImageFromReference
          :reference="readyMedia[0].image.medium"
          :contain="false"
          :max-height="303"
          :overlay-icon="(readyMedia[0].type === 'video' || readyMedia[0].type === 'youtube')
            ? 'mdi-play-circle' : ''"
        />
        <div class="subtitle-2 ml-3">
          {{ readyMedia[0].caption }}
        </div>
      </div>

      <div v-if="(readyMedia.length > 0) && (readyMedia.length > 1 || card.text.length > 0)">
        <v-divider
          class="mt-1"
        />

        <v-card
          class="d-flex flex-wrap pa-2 mb-4 transparent-background"
          flat
        >
          <ImageFromReference
            v-for="media in readyMedia"
            :key="media.id"
            :max-height="70"
            :min-height="70"
            :max-width="70"
            :contain="false"
            :black-background="true"
            :reference="media.image.small"
            :overlay-icon="(media.type === 'video' || media.type === 'youtube')
              ? 'mdi-play-circle' : ''"
            class="mr-1 mb-1"
          />
        </v-card>
      </div>

      <div
        v-if="isEmpty"
        class="text-center overline grey--text pa-1"
      >
        Empty card
      </div>

      <v-overlay
        v-if="hasMarking"
        absolute
        :opacity="0.0"
        class="d-flex align-end flex-column justify-space-between"
        style="right: -10px; top: -10px;"
      >
        <v-card
          color="primary"
          raised
          class="pa-1 ma-1"
        >
          <v-icon
            v-if="card.type === 'target'"
            color="secondary"
            size="medium"
            title="Target Behavior"
          >
            mdi-bullseye-arrow
          </v-icon>
          <v-icon
            v-if="card.trigger !== null"
            color="secondary"
            size="medium"
            title="Trigger"
          >
            mdi-alarm-light
          </v-icon>
          <v-icon
            v-if="isHidden && !isTotallyHidden"
            color="secondary"
            size="medium"
            title="Hidden"
          >
            mdi-eye-off
          </v-icon>
          <v-icon
            v-if="isTotallyHidden"
            color="grey"
            size="medium"
            title="Hidden"
          >
            mdi-cellphone-off
          </v-icon>
        </v-card>
      </v-overlay>

      <v-overlay
        v-if="hasTag"
        absolute
        :opacity="0.0"
        class="d-flex align-end justify-end"
        style="right: -5px; bottom: -5px;"
      >
        <v-chip
          small
          color="primary"
          text-color="secondary"
        >
          {{ card.tag }}
        </v-chip>
      </v-overlay>
    </v-card>
  </v-hover>
</template>


<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

import { db } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';

import cardStyles from '@/config/card-styles';

export default {
  name: 'CardPreview',
  components: {
    ImageFromReference,
  },
  props: {
    cardId: {
      type: String,
      default: '',
    },
    templateId: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      card: {
        title: '', text: '', style: null, type: null,
      },
      cardMedia: [],
    };
  },
  computed: {
    hasMarking() {
      return this.card.type === 'target' || this.card.trigger !== null || this.isHidden;
    },
    hasTag() {
      return this.card.tag != null;
    },
    isHidden() {
      return this.card.hiddenRoles !== undefined && this.card.hiddenRoles.length > 0;
    },
    isTotallyHidden() {
      // Hidden from everybody and no triggers
      return this.card.trigger === null && this.card.hiddenRoles !== undefined
      && this.card.hiddenRoles.length === 3;
    },
    compiledMarkdown() {
      return marked(this.card.text, { sanitizer: DOMPurify.sanitize });
    },
    isEmpty() {
      return this.card === null || (this.card.title.length === 0 && this.card.text.length === 0
        && this.cardMedia.length === 0);
    },
    readyMedia() {
      return this.cardMedia.filter((media) => media.image !== undefined);
    },
    style() {
      if (this.card.style === null) return null;
      return cardStyles.find((item) => item.name === this.card.style);
    },
  },
  created() {
    this.$bind('card', db.collection(`templates/${this.templateId}/cards`).doc(this.cardId));
    this.$bind('cardMedia', db.collection(`templates/${this.templateId}/cards/${this.cardId}/media`).orderBy('position'));
  },
  methods: {
    selectCard() {
      if (!this.selected) this.$emit('edit-card', this.card.id);
    },

  },

};
</script>


<style scoped>
  .empty-card { background-color: #EEEEEE; border-style: dashed; }
  .selected-card { border: 2px #33843F solid; }
  .transparent-background { background-color: transparent }
  .totally-hidden {
    background-color: #EEEEEE;
    border-style: dashed;
    border-color: #aaa;
    background-image: none !important;
    color: #222 !important;
  }
</style>

<style>
   div.body-2 > p > img {
    max-width: 200px;
  }
</style>
