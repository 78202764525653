<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    :persistent="loading"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        title="Add Media"
        class="ml-3"
        v-on="on"
      >
        <v-icon>mdi-image-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        Add media for this card
      </v-card-title>

      <v-text-field
        v-model="title"
        class="px-8"
        label="Title (optional)"
        counter="50"
        :disabled="loading"
      />

      <v-tabs
        v-model="tab"
        class="px-8 mb-4"
      >
        <v-tab :disabled="webUrl !== ''">
          <v-icon left>
            mdi-upload
          </v-icon>Upload
        </v-tab>
        <v-tab :disabled="fileData">
          <v-icon left>
            mdi-link
          </v-icon>Web URL
        </v-tab>

        <v-tab-item class="py-4">
          Upload an image, video or audio.

          <v-file-input
            v-model="fileData"
            show-size
            accept="image/png, image/jpeg, image/webp,
          video/mp4, video/ogg, video/webm, video/quicktime,
          audio/mpeg, audio/ogg, audio/webm"
            label="Media file"
            :disabled="loading"
            :rules="maxFileSize"
          />

          <v-btn
            title="Upload"
            :disabled="loading || !fileData"
            @click="uploadCardMedia"
          >
            <v-icon left>
              mdi-cloud-upload-outline
            </v-icon>
            Upload
          </v-btn>

          <v-progress-linear
            v-if="loading"
            class="mt-4"
            color="primary"
            height="10"
            :value="uploadProgress"
            striped
          />
        </v-tab-item>
        <v-tab-item class="py-4">
          <v-alert
            outlined
            type="warning"
            dense
          >
            External content is hosted outside this platform and could change without notice.
            <br>
            Currently only <strong>YouTube</strong> links are allowed.
          </v-alert>

          <v-text-field
            v-model="webUrl"
            label="URL"
            :rules="validWebURL"
            :disabled="loading"
          />

          <div class="caption grey--text mb-2">
            <strong>Note!</strong>
            Some YouTube videos can not play embedded in the app
            because the copyright owner has disabled it.
          </div>

          <v-btn
            title="Submit"
            :disabled="loading || !webUrl"
            @click="submitWebUrl"
          >
            <v-icon left>
              mdi-link
            </v-icon>
            Submit
          </v-btn>
        </v-tab-item>
      </v-tabs>

      <div
        v-if="addedCount > 0 && !loading"
        class="py-0 px-8"
      >
        The media be processed in the background.
        <br>
        You can add more media or close this dialog.
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

import { extname } from 'path';
import * as cryptoRandomString from 'crypto-random-string';

import { db } from '@/firebase';

export default {
  name: 'UploadCardMediaDialog',

  props: {
    templateId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      dialog: false,
      storageRef: firebase.storage().ref(),
      title: '',
      fileData: null,
      uploadProgress: 0.0,
      addedCount: 0,
      maxFileSize: [
        (value) => !value || value.size < 250 * 1024 * 1024 || 'Media must be less than 250 MB!',
      ],
      webUrl: '',
      validWebURL: [
        (value) => !value || this.isWebURLValid(value) || 'Url is invalid!',
      ],

    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  watch: {
    dialog: {
      handler(val, oldVal) {
        if (oldVal === false && val === true) {
          // Reset
          this.tab = null;
          this.title = '';
          this.fileData = null;
          this.uploadProgress = 0.0;
          this.addedCount = 0;

          this.webUrl = '';
        }
      },
    },
  },

  methods: {
    async uploadCardMedia() {
      this.$store.commit('setLoading', true);

      const cardMediaRef = await db.collection(`templates/${this.templateId}/cards/${this.cardId}/media`)
        .add({
          title: this.title,
          uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
          processedAt: null,
          position: this.count + 1,
        });

      const ext = extname(this.fileData.name);
      const unique = cryptoRandomString({ length: 8 });
      const templateUploadRef = this.storageRef
        .child(`uploads/users/${this.user.uid}/templates/${this.templateId}/cards/${this.cardId}/\
media/${cardMediaRef.id}/upload.${unique}${ext}`);

      const uploadTask = templateUploadRef.put(this.fileData);

      const self = this;
      uploadTask.on('state_changed', (snapshot) => {
        self.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, (error) => {
        // Handle unsuccessful uploads
      }, () => {
        self.title = '';
        self.fileData = null;
        self.uploadProgress = 0.0;
        self.$store.commit('setLoading', false);
        self.addedCount += 1;
      });
    },

    isWebURLValid(value) {
      if (!value.startsWith('https://')) return false;
      if (!value.includes('youtu')) return false; // youtube.com and youtu.be

      return true;
    },

    async submitWebUrl() {
      if (!this.isWebURLValid(this.webUrl)) return;

      this.$store.commit('setLoading', true);

      const cardMediaRef = await db.collection(`templates/${this.templateId}/cards/${this.cardId}/media`)
        .add({
          title: this.title,
          webUrl: this.webUrl,
          uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
          processedAt: null,
          position: this.count + 1,
        });

      this.webUrl = '';
      this.title = '';
      this.addedCount += 1;
      this.$store.commit('setLoading', false);
    },
  },



};
</script>
