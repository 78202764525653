<template>
  <v-container class="ma-0">
    <div class="mx-3 mb-4">
      <v-row>
        <v-dialog
          v-model="dialog"
          max-width="400"
        >
          <template #activator="{ on }">
            <v-btn v-on="on">
              Create a Reward
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline">
              Create a reward
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="title"
                label="Title"
                counter="50"
                required
                autofocus
              />
              <span class="font-weight-bold">Note.</span> Informations are changed later.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                type="submit"
                :disabled="loading"
                @click="createReward"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-switch
          v-model="showPublic"
          label="Show Public Rewards"
        />
      </v-row>
      <v-row>
        <TagList
          v-if="showPublic"
          class="mx-4"
          :tags="availableTags"
          @selected-tags="selectedTags"
        />
      </v-row>
    </div>

    <RewardRow
      v-for="reward in rewards"
      :key="reward.id"
      :reward="reward"
      :read-only="reward.ownerId != user.uid"
      @click="editReward(reward.id)"
    />
    <v-btn
      v-if="isMore && !filterTags"
      block
      @click="page += 1"
    >
      Next
    </v-btn>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '@/firebase';

import RewardRow from '@/components/rewards/RewardRow.vue';

import TagList from '@/components/TagList.vue';
import rewardTags from '@/config/reward-tags.js';

const pageSize = 20;

export default {
  name: 'RewardRowList',
  components: {
    RewardRow,
    TagList,
  },
  data() {
    return {
      title: '',
      dialog: false,
      availableTags: rewardTags,
      tags: [],
      rewards: [],
      showPublic: false,
      page: 1,
      isMore: true,
      lastVisible: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
    filterTags() {
      return this.tags.length > 0;
    },
  },

  watch: {
    page: {
      immediate: true,
      handler(val, oldVal) {

        if (this.page === 1 && this.lastVisible === null) {
          // First time load
          this.$bind('rewards', db.collection('rewards')
            .where('ownerId', '==', this.user.uid)
            .orderBy('createdAt', 'desc')
            .limit(pageSize));
        } else {
          // Load more
          if (!this.showPublic) {
            this.$bind('rewards', db.collection('rewards')
              .where('ownerId', '==', this.user.uid)
              .orderBy('createdAt', 'desc')
              .startAfter(this.lastVisible)
              .limit(pageSize));
          } else {
            this.$bind('rewards', db.collection('rewards')
              .where('public', '==', true)
              .startAfter(this.lastVisible)
              .limit(pageSize));
          }
        }
      },
    },

    showPublic: {
      handler(val, oldVal) {
        // Reset pagination
        this.page = 1;
        this.isMore = true;
        this.lastVisible = null;

        if (val === true) {
          this.$bind('rewards', db.collection('rewards')
            .where('public', '==', true)
            .limit(pageSize));
        } else {
          this.$bind('rewards', db.collection('rewards')
            .where('ownerId', '==', this.user.uid)
            .orderBy('createdAt', 'desc')
            .limit(pageSize));
        }
      },
    },

    tags: {
      handler(val, oldVal) {
        if (this.tags.length > 0) {
          this.$bind('rewards', db.collection('rewards')
            .where('tags', 'array-contains-any', this.tags).where('public', '==', true));
        }
      },
    },

    rewards: {
      handler(val, oldVal) {
        const newRewards = val;

        if (newRewards.length === 0) return; // Ignore this

        if (newRewards.length === pageSize) {
          this.isMore = true;
        } else if (newRewards.length < pageSize) {
          this.isMore = false;
        } 

        const lastVisibleId = newRewards[newRewards.length - 1].id;

        db.collection('rewards')
          .doc(lastVisibleId)
          .get()
          .then((snapshot) => {
            this.lastVisible = snapshot;
          });

      },
    },
  },

  methods: {
    editReward(rewardId) {
      this.$router.push(`/rewards/${rewardId}`);
    },
    createReward() {
      this.$store.commit('setLoading', true);
      this.$store.commit('clearError');

      db.collection('rewards')
        .add({
          title: this.title,
          description: '',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          ownerId: this.user.uid,
          tags: [],
          image: null,
          public: false,
        })
        .then((docRef) => {
          this.$store.commit('setLoading', false);
          this.$store.commit('clearError');
          this.$router.push(`/rewards/${docRef.id}`);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    },

    selectedTags(tags) {
      this.tags = tags;
    },
  },

};
</script>
