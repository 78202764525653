<template>
  <nav>
    <v-progress-linear
      v-if="loading"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="primary"
    />

    <v-app-bar
      flat
      class="grey lighten-5"
    >
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      />

      <v-toolbar-title>
        <v-img
          src="@/assets/images/bf-logo-no-smile.svg"
          width="130"
          height="40"
          contain
          aspect-ratio="1"
        />
      </v-toolbar-title>
      <v-spacer />

      <v-menu
        v-if="isAuthenticated"
        offset-y
        :nudge-bottom="10"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            fab
            small
            v-on="on"
          >
            {{ userInitials }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ user.email }}</v-list-item-title>
              <v-list-item-subtitle v-if="isStaff">
                Beanfee <strong>staff</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Beanfee user
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-btn
              text
              color="grey"
              @click="updatePassword"
            >
              <span>Update Password</span>
              <v-icon right>
                mdi-form-textbox-password
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-divider />
          <v-list-item-action>
            <v-spacer />
            <v-btn
              v-if="isAuthenticated"
              text
              color="grey"
              @click="logout"
            >
              <span>Logout</span>
              <v-icon right>
                mdi-exit-to-app
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      class="secondary white--text"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-img
              src="@/assets/images/bf-logo-smile-dark.svg"
              width="195"
              height="57"
              class="mb-2"
            />
          </v-list-item-title>

          <v-list-item-subtitle class="white--text">
            <span class="font-weight-bold">Be</span>havior
            <span class="font-weight-bold">An</span>alysis
            and
            <span class="font-weight-bold">Fee</span>dback
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <div v-if="isAuthenticated">
        <v-list
          nav
          dense
        >
          <v-list-item
            link
            router-link
            to="/"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            router-link
            to="/programs"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Programs</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            router-link
            to="/templates"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-table-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Templates</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            router-link
            to="/rewards"
          >
            <v-list-item-icon>
              <v-icon>mdi-gift</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Rewards</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            router-link
            to="/achievements"
          >
            <v-list-item-icon>
              <v-icon>mdi-trophy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Achievements</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isBeanfeeStaff(user.email)"
            link
            router-link
            to="/users"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
      <v-divider />
      <div class="mt-4 overline text-center">
        <strong>Ver. </strong> {{ version }}
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="errorSnackbar"
      color="error"
      top
    >
      <v-icon left>
        mdi-exclamation-thick
      </v-icon>
      {{ error }}
      <v-btn
        text
        @click="closeError()"
      >
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userInitials() {
      return this.$store.getters.userInitials;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isStaff() {
      return this.$store.getters.isStaff;
    },
    loading() {
      return this.$store.getters.loading;
    },
    version() {
      return this.$version;
    },
    errorSnackbar: {
      get() {
        return this.$store.getters.error != null;
      },
      set(val) {
        if (val === false) {
          this.$store.commit('clearError');
        }
      },
    },
    error() {
      return this.$store.getters.error;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    updatePassword() {
      this.$router.push('/update-password');
    },
    closeError() {
      this.$store.commit('clearError');
    },
    isBeanfeeStaff(email) {
      const allowedUsers = [
        'eirikur@beanfee.com', 
        'helgi@beanfee.com',
        'sveinn@base.is',
        'beanfee@beanfee.com',
      ];

      return allowedUsers.includes(email);
    },
  },
};
</script>
