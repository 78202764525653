<template>
  <v-container class="ma-0">
    <v-row class="mb-8">
      <v-dialog
        v-model="dialog"
        max-width="400"
        :persistent="loading"
      >
        <template #activator="{ on }">
          <v-btn
            title="Modify Beans"
            class="ml-3"
            v-on="on"
          >
            Modify Beans
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="headline">
            Add or remove beans
          </v-card-title>

          <v-text-field
            v-model="incrementBeans"
            class="px-8"
            label="Beans"
            :disabled="loading"
          />
          <div class="caption grey--text ml-10">
            Use minus beans to lower
          </div>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>

            <v-btn
              color="primary"
              text
              :disabled="loading"
              @click="customIncrementBeans"
            >
              Modify
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <TransactionRow
      v-for="transaction in transactions"
      :key="transaction.id"
      :transaction="transaction"
    />
  </v-container>
</template>

<script>
import { db, functions } from '@/firebase';

import TransactionRow from '@/components/programs/transactions/TransactionRow.vue';

const callableCustomIncrementBeans = functions.httpsCallable('callableCustomIncrementBeans');

export default {
  name: 'ProgramsTransactions',
  components: {
    TransactionRow,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      incrementBeans: null,
      transactions: [],
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  created() {
    this.$bind('transactions', db.collection(`programs/${this.programId}/transactions`).orderBy('createdAt', 'desc'));
  },

  methods: {
    customIncrementBeans() {
      if (this.incrementBeans === null || this.incrementBeans === 0
          || Number.isNaN(Number(this.incrementBeans)) === true) {
        this.$store.commit('setLoading', false);
        this.$store.commit('setError', 'Invalid Bean Number');
        this.dialog = false;
        return;
      }

      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to add or delete beans?') === false) return;

      this.dialog = false;

      this.$store.commit('setLoading', true);
      callableCustomIncrementBeans({
        programId: this.programId,
        incrementBeans: this.incrementBeans,
      }).then((result) => {
        this.$store.commit('setLoading', false);
        console.log(result);
      });
    },

  },

};
</script>
