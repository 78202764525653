<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    :persistent="loading"
  >
    <template #activator="{ on }">
      <v-btn
        title="Add Image"
        :block="block"
        v-on="on"
      >
        <v-icon left>
          mdi-image-plus
        </v-icon>
        {{ buttonCaption }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        Upload an image
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="fileData"
          show-size
          accept="image/png, image/jpeg, image/webp"
          placeholder="Upload an image file"
          label="Image file"
          prepend-icon="mdi-image-outline"
          :disabled="loading || uploadProgress > 0.0"
        />

        <v-btn
          title="Upload"
          :disabled="uploadProgress > 0 || loading || !fileData"
          @click="uploadImage()"
        >
          <v-icon left>
            mdi-cloud-upload-outline
          </v-icon>
          Upload
        </v-btn>

        <v-progress-linear
          v-if="loading"
          class="mt-4"
          color="primary"
          height="10"
          :value="uploadProgress"
          striped
        />
        <div
          v-if="processMessage && uploadProgress === 100 && !loading"
          class="mt-2"
        >
          The image file will be processed in the background.
          <br>
          You can close this dialog.
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

import { extname } from 'path';
import * as cryptoRandomString from 'crypto-random-string';

import { db } from '@/firebase';

export default {
  name: 'UploadCardMediaDialog',

  props: {
    uploadFilepath: {
      type: String,
      required: true,
      default: '',

    },
    buttonCaption: {
      type: String,
      default: 'Add Image',
    },
    block: {
      type: Boolean,
      default: false,
    },
    processMessage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      storageRef: firebase.storage().ref(),
      dialog: false,
      fileData: null,
      uploadProgress: 0.0,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  watch: {
    dialog: {
      handler(val, oldVal) {
        if (oldVal === false && val === true) {
          // Reset
          this.fileData = null;
          this.uploadProgress = 0.0;
        }
      },
    },
  },

  methods: {
    async uploadImage() {
      this.$store.commit('setLoading', true);

      if (!this.uploadFilepath.endsWith('/')) this.uploadFilepath += '/';

      const ext = extname(this.fileData.name);
      const unique = cryptoRandomString({ length: 8 });
      const uploadFilepath = `${this.uploadFilepath}upload.${unique}${ext}`;
      const templateUploadRef = this.storageRef.child(uploadFilepath);

      this.$emit('upload-started');
      const uploadTask = templateUploadRef.put(this.fileData);

      const self = this;
      uploadTask.on('state_changed', (snapshot) => {
        self.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, (error) => {
        // Handle unsuccessful uploads
      }, () => {
        self.$store.commit('setLoading', false);
        self.$emit('upload-finished', uploadFilepath);
      });
    },
  },



};
</script>
