<template>
  <v-container class="ma-0">
    <div class="mx-3 mb-4">
      <v-row>
        <v-dialog
          v-model="dialog"
          max-width="400"
        >
          <template #activator="{ on }">
            <v-btn v-on="on">
              Create Template
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline">
              Create a new program template
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="title"
                label="Title"
                counter="50"
                required
                autofocus
              />
              <span class="font-weight-bold">Note.</span> Title can be changed later.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                type="submit"
                :disabled="loading"
                @click="createTemplate"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-switch
          v-model="showPublic"
          label="Show Public Templates"
        />
      </v-row>
    </div>

    <TemplateRow
      v-for="template in templates"
      :key="template.id"
      :template="template"
      :read-only="template.ownerId != user.uid"
      @click="editTemplate(template.id)"
    />
    <v-btn
      v-if="isMore"
      block
      @click="page += 1"
    >
      Next
    </v-btn>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '@/firebase';

import TemplateRow from '@/components/templates/TemplateRow.vue';

const pageSize = 20;

export default {
  name: 'TemplatesList',
  components: {
    TemplateRow,
  },
  data() {
    return {
      title: '',
      dialog: false,
      templates: [],
      showPublic: false,
      page: 1,
      isMore: true,
      lastVisible: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    page: {
      immediate: true,
      handler(val, oldVal) {

        if (this.page === 1 && this.lastVisible === null) {
          // First time load
          this.$bind('templates', db.collection('templates')
            .where('ownerId', '==', this.user.uid)
            .orderBy('createdAt', 'desc')
            .limit(pageSize));
        } else {
          // Load more
          if (!this.showPublic) {
            this.$bind('templates', db.collection('templates')
              .where('ownerId', '==', this.user.uid)
              .orderBy('createdAt', 'desc')
              .startAfter(this.lastVisible)
              .limit(pageSize));
          } else {
            this.$bind('templates', db.collection('templates')
              .where('public', '==', true)
              .startAfter(this.lastVisible)
              .limit(pageSize));
          }
        }
      },
    },

    showPublic: {
      handler(val, oldVal) {
        // Reset pagination
        this.page = 1;
        this.isMore = true;
        this.lastVisible = null;

        if (val === true) {
          this.$bind('templates', db.collection('templates')
            .where('public', '==', true)
            .limit(pageSize));
        } else {
          this.$bind('templates', db.collection('templates')
            .where('ownerId', '==', this.user.uid)
            .orderBy('createdAt', 'desc')
            .limit(pageSize));
        }
      },
    },

    templates: {
      handler(val, oldVal) {
        const newTemplates = val;

        if (newTemplates.length === 0) return; // Ignore this

        if (newTemplates.length === pageSize) {
          this.isMore = true;
        } else if (newTemplates.length < pageSize) {
          this.isMore = false;
        } 

        const lastVisibleId = newTemplates[newTemplates.length - 1].id;

        db.collection('templates')
          .doc(lastVisibleId)
          .get()
          .then((snapshot) => {
            this.lastVisible = snapshot;
          });

      },
    },
  },

  methods: {
    editTemplate(templateId) {
      this.$router.push(`/templates/${templateId}`);
    },
    createTemplate() {
      this.$store.commit('setLoading', true);
      this.$store.commit('clearError');

      db.collection('templates')
        .add({
          title: this.title,
          description: '',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          published: false,
          ownerId: this.user.uid,
          image: null,
          tags: [],
          cardsTags: [],
          measurements: [],
          version: 1,
        })
        .then((docRef) => {
          docRef.collection('cards').add({
            title: '',
            text: '',
            position: 0,
            tag: null,
            style: null,
            trigger: null,
            triggerRoles: [],
            type: 'target', // First card is always target
          })
            .then((docCardRef) => {
              this.dialog = false;
              this.title = '';

              this.$store.commit('setLoading', false);
              this.$store.commit('clearError');

              this.$router.push(`/templates/${docRef.id}`);
            })
            .catch((error) => {
              console.error('Error adding card: ', error);
            });
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    },
  },

};
</script>
