<template>
  <v-card
    elevation="2"
    class="caption my-2 pa-1 times-feedback"
    color="yellow"
  >
    <strong>{{ successPercentage }}%</strong> success
    <span v-if="showBeans">yields <strong>{{ beanPrice }}</strong> beans which</span>
    is at least <strong>{{ timesSuccess }}</strong>
    sucessful feedback events in the current program
  </v-card>
</template>


<script>

export default {
  name: 'BeanPrice',
  props: {
    successPercentage: {
      type: Number,
      required: true,
    },
    maxBeans: {
      type: Number,
      required: true,
    },
    showBeans: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    beanPrice() {
      return Math.round((this.successPercentage / 100) * this.maxBeans);
    },
    timesSuccess() {
      return Math.floor(this.beanPrice / 10);
    },
  },
};
</script>

<style scoped>
.times-feedback {
  font-size: 11px !important;
  line-height: 15px;
}
</style>
