<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="600"
  >
    <v-card>
      <span v-if="isSubject">
        <v-card-title class="text-h6">
          You have selected the role of
          &nbsp;
          <strong>Subject</strong>
        </v-card-title>
        <v-card-text>
          Self assign the subject role is mainly used for testing.
        </v-card-text>
      </span>

      <span v-if="isSupporter">
        <v-card-title class="text-h6">
          You have selected the role of
          &nbsp;
          <strong>Supporter</strong>
        </v-card-title>
        <v-card-text>
          <p>
            As a <strong>Supporter</strong>, you help the <strong>Subject</strong>
            reach their goals by giving feedback and possible rewards.
          </p>
          <p>
            Any users logged into this account ({{ user.email }}) share this role.
          </p>
        </v-card-text>
      </span>

      <span v-if="isObserver">
        <v-card-title class="text-h6">
          You have selected the role of
          &nbsp;
          <strong>Observer</strong>
        </v-card-title>
        <v-card-text>
          <p>
            As an <strong>Observer</strong>, you support the <strong>Supporter</strong>
            in training the <strong>Subject</strong> and may provide rewards for progress.
          </p>
          <p>
            Any users logged into this account ({{ user.email }}) share this role.
          </p>
        </v-card-text>
      </span>

      <span v-if="isNoRole">
        <v-card-title class="text-h6">
          You have not selected any role for {{ user.email }}
        </v-card-title>
        <v-card-text>
          <p>
            <strong>If you don't select a role</strong> for {{ user.email }}, a code will
            be generated for each role at the end of step 6 in this setup, and only
            <strong>one</strong> user can use each code.
          </p>
          <p>
            <v-row>
              <v-col>
                <v-img
                  src="@/assets/images/use-code.png"
                  width="300"
                  contain
                  eager
                />
              </v-col>
              <v-col>
                <p>
                  If <strong>you</strong> want to take on a role, simply enter
                  the role code (for Supporter or Observer) into the Beanfee App after logging in.
                </p>
                <p>
                  <strong>Note.</strong> A subject can use their role code
                  without logging in under a specific account.
                </p>
              </v-col>
            </v-row>
          </p>
          <p>
            Without selecting a role or using a code, {{ user.email }} will
            be an <strong>inactive</strong> observer.
          </p>
        </v-card-text>
      </span>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="dialog = false; $emit('continue');"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {

  name: 'RoleNoticeDialog',

  props: {
    value: Boolean,
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    user() {
      return this.$store.getters.user;
    },

    roles() {
      return this.$store.getters.roles;
    },

    isSupporter() {
      return this.roles.supporter.me;
    },

    isObserver() {
      return this.roles.observer.me && !this.isSupporter;
    },

    isSubject() {
      return this.roles.subject.me && !this.roles.supporter.me && !this.roles.observer.me;
    },

    isNoRole() {
      return (!this.roles.supporter.me && !this.roles.observer.me && !this.roles.subject.me);
    },

  },

};
</script>
