<template>
  <v-form v-model="valid">
    <v-row>
      <v-col md="6">
        <v-text-field
          v-model="rewardInfo.title"
          label="Title"
          counter="50"
          required
          @blur="saveInfo()"
        />

        <v-textarea
          v-model="rewardInfo.description"
          label="Description"
          counter="250"
          @blur="saveInfo()"
        />

        <v-combobox
          v-model="rewardInfo.tags"
          :items="tags"
          :rules="tagRequired"
          counter="3"
          chips
          clearable
          multiple
          label="Rewards Tags"
          @blur="saveInfo()"
        >
          <template #selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="removeTag(item)"
            >
              <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>

        <v-switch
          v-model="rewardInfo.public"
          :label="`Reward is public`"
        />
        <div class="caption grey--text mb-6">
          Everybody can use public reward in their own program.
        </div>

        <v-btn
          title="Save"
          :disabled="loading || !valid"
          @click="saveInfo"
        >
          <v-icon left>
            mdi-content-save-edit
          </v-icon>
          Save
        </v-btn>
      </v-col>
      <v-col md="6">
        <div
          v-if="!loading && processingUpload"
          class="text-center ma-10"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="grey lighten-2"
            indeterminate
          />

          <div class="grey--text lighten-2 overline mt-2">
            Processing
          </div>
        </div>

        <ImageFromReference
          v-if="rewardInfo.image !== null"
          :reference="rewardInfo.image.medium"
        />

        <v-spacer class="my-4" />
        <UploadImage
          :upload-filepath="`uploads/users/${user.uid}/rewards/${rewardId}/`"
          @upload-started="imageUploadStarted()"
          @upload-finished="imageUploadFinished()"
        />
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';
import UploadImage from '@/components/UploadImage.vue';

import rewardsTags from '@/config/reward-tags.js';

export default {
  name: 'RewardEditInfo',
  components: {
    ImageFromReference,
    UploadImage,
  },
  props: {
    rewardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,

      tagRequired: [
        (v) => (v !== undefined && v.length > 0) || 'You must specify one tag',
      ],

      storageRef: firebase.storage().ref(),
      tags: rewardsTags,
      processingUpload: false,
      rewardInfo: {
        title: '',
        description: '',
        image: null,
        tags: [],
        public: false,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  watch: {
    'rewardInfo.image': {
      handler(val, oldVal) {
        if (oldVal === null && val !== null) {
          this.processingUpload = false;
        }
      },
    },

  },

  created() {
    this.$bind('rewardInfo', db.collection('rewards').doc(this.rewardId));
  },
  methods: {
    saveInfo() {
      if (!this.rewardInfo.tags || this.rewardInfo.tags.length === 0) {
        this.rewardInfo.tags = [];
      }
      this.$store.commit('setLoading', true);

      db.collection('rewards').doc(this.rewardId).update(this.rewardInfo).then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
      });
    },

    imageUploadStarted() {
      this.rewardInfo.image = null;
    },

    imageUploadFinished() {
      this.processingUpload = true;
    },

  },

};
</script>
