<template>
  <div class="text-center pa-4">
    <v-dialog 
      v-model="dialog2" 
      max-width="600"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="hasSavedStates"
          color="primary"
          :disabled="loading"
          :loading="loading" 
          v-bind="attrs" 
          v-on="on"
          @click="openStatesDialog"
        >
          Load last
        </v-btn>
      </template>

      <v-card>
        <v-card-title 
          class="headline primary" 
          style="color: white"
        >
          <v-icon 
            class="pa-2 round-icon"
            color="white"
          >
            mdi-cloud-arrow-down-outline
          </v-icon>
          Last 10 states
        </v-card-title>

        <v-divider class="mb-4" />

        <v-card-text>
          <div v-if="states.length === 0">
            No saved states available.
          </div>
          <v-btn
            v-for="(state, index) in states"
            :key="index"
            class="ma-2"
            @click="loadSpecificState(index)"
          >
            {{ state.roles.subject.nickname }}  ({{ formatTimestamp(state.timestamp) }}) 
            {{ state.schedule ? state.schedule.length : 0 }} events - Starts 
            {{ state.schedule[0].start }}
          </v-btn>
        </v-card-text>

        <v-divider />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog2: false,
      states: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    hasSavedStates() {
      const savedStates = JSON.parse(localStorage.getItem('startProgramStates')) || [];
      return savedStates.length > 0;
    },
  },
  methods: {
    openStatesDialog() {
      this.dialog2 = true;
      this.loadStates();
      this.$emit('statesDialogOpened');
    },
    loadStates() {
      const savedStates = JSON.parse(localStorage.getItem('startProgramStates')) || [];
      this.states = savedStates;
    },
    loadSpecificState(index) {
      const state = this.states[index];
      if (state) {
        this.$store.dispatch('loadSpecificState', index);
        this.$store.dispatch('setSchedule', []);
        this.dialog2 = false;
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
};
</script>
