import Vue from 'vue';
import { firestorePlugin } from 'vuefire';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './firebase';
import './filters.js';


Vue.prototype.$version = '0.2.0 (2024-09-05)';

Vue.config.productionTip = false;

// Suppress annoying warning
// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
/*eslint-disable */
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};
/* eslint-enable */

Vue.use(firestorePlugin);

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
