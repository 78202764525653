export default {
  state: {
    loading: false,
    error: null,
    // User settings
    showPublicPrograms: false,
    showFinishedPrograms: false,
    showPublicRewards: false,
    showPublicAchievements: false,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
    // User settings
    setShowPublicPrograms(state, showPublicPrograms) {
      state.showPublicPrograms = showPublicPrograms;
    },
    setShowFinishedPrograms(state, showFinishedPrograms) {
      state.showFinishedPrograms = showFinishedPrograms;
    },
    setShowPublicRewards(state, showPublicRewards) {
      state.showPublicRewards = showPublicRewards;
    },
    setShowPublicAchievements(state, showPublicAchievements) {
      state.showPublicAchievements = showPublicAchievements;
    },
  },
  actions: {
    // Ideally we should dispatch an action rather than calling a mutation directly from routines.
    clearError({ commit }) {
      commit('clearError');
    },
    setError({ commit }, error) {
      commit('setError', error);
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    error(state) {
      return state.error;
    },
    // User settings
    showPublicPrograms(state) {
      return state.showPublicPrograms;
    },
    showFinishedPrograms(state) {
      return state.showFinishedPrograms;
    },
    showPublicRewards(state) {
      return state.showPublicRewards;
    },
    showPublicAchievements(state) {
      return state.showPublicAchievements;
    },
  },
};
