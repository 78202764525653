<template>
  <v-card
    class="mx-auto"
  >
    <v-row
      class="mb-4 px-2"
      align="center"
      justify="space-around"
    >
      <v-col>
        <div class="caption grey--text">
          Event At
        </div>
        <div>{{ event.eventAt.toDate() | formatDateDetail }}</div>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Subject Feedback
        </div>
        <v-icon
          v-if="event.subjectSuccessFeedback == true"
          class="pa-1 round-icon true-feedback-bg"
          color="white"
          title="Success"
        >
          mdi-check
        </v-icon>
        <v-icon
          v-if="event.subjectSuccessFeedback == false"
          class="pa-1 round-icon false-feedback-bg"
          color="white"
          title="Fail"
        >
          mdi-close
        </v-icon>

        <v-icon
          v-if="event.subjectSuccessFeedback == null"
          class="pa-1 round-icon missing-feedback-bg"
          color="white"
          title="Missing"
        >
          mdi-help
        </v-icon>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Supporter Feedback
        </div>
        <v-icon
          v-if="event.supporterSuccessFeedback == true"
          class="pa-1 round-icon true-feedback-bg"
          color="white"
          title="Success"
        >
          mdi-check
        </v-icon>
        <v-icon
          v-if="event.supporterSuccessFeedback == false"
          class="pa-1 round-icon false-feedback-bg"
          color="white"
          title="Fail"
        >
          mdi-close
        </v-icon>

        <v-icon
          v-if="event.supporterSuccessFeedback == null"
          class="pa-1 round-icon missing-feedback-bg"
          color="white"
          title="Missing"
        >
          mdi-help
        </v-icon>
      </v-col>
      <v-col class="text-end mt-1">
        <v-btn
          color="red lighten-3"
          @click="deleteEvent()"
        >
          <v-icon
            left
          >
            mdi-delete
          </v-icon>Delete
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>


export default {
  name: 'EventRow',
  components: {

  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  methods: {
    deleteEvent() {
      this.$emit('delete-event');
    },

  },

};
</script>
