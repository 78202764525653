<template>
  <v-row>
    <v-col md="8">
      <v-card
        class="pa-3"
        outlined
      >
        <v-card-title>
          Information
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="caption grey--text">
                Organization
              </div>
              <div v-if="program.organization !== undefined">
                {{ program.organization }}
              </div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Owner Email
              </div>
              <div v-if="program.ownerEmail !== undefined">
                {{ program.ownerEmail }}
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />

          <v-row>
            <v-col>
              <div class="caption grey--text">
                Created At
              </div>
              <div v-if="program.createdAt !== undefined">
                {{ program.createdAt.toDate() | formatDateDetail }}
              </div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                First Event At
              </div>
              <div v-if="program.firstEventAt !== undefined">
                {{ program.firstEventAt.toDate() | formatDateDetail }}
              </div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Last Event At
              </div>
              <div v-if="program.lastEventAt !== undefined">
                {{ program.lastEventAt.toDate() | formatDateDetail }}
              </div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Total Event Count
              </div>
              <div>{{ program.eventTotalCount }}</div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />

          <v-row>
            <v-col>
              <div class="caption grey--text">
                Max Beans
              </div>
              <div>{{ program.maxBeans }}</div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Total Beans
              </div>
              <div>{{ program.totalBeans }}</div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Gained Beans
              </div>
              <div>{{ program.gainedBeans }}</div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Spent Beans
              </div>
              <div>{{ program.spentBeans }}</div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />

          <v-row>
            <v-col>
              <div class="caption grey--text">
                Subject Nickname
              </div>
              <div>{{ program.subjectNickname }}</div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Supporter Nickname
              </div>
              <div>{{ program.supporterNickname }}</div>
            </v-col>
            <v-col>
              <div class="caption grey--text">
                Observer Nickname
              </div>
              <div>{{ program.observerNickname }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        class="pa-3 mt-4"
        outlined
      >
        <v-card-text>
          <v-btn
            outlined
            @click="store"
          >
            <v-icon left>
              mdi-gift
            </v-icon>
            Rewards
          </v-btn>

          <span class="ml-4">Add new rewards and modify title, quantity and etc.</span>

          <v-divider class="my-4" />

          <v-btn
            outlined
            @click="statistics"
          >
            <v-icon left>
              mdi-chart-line
            </v-icon>
            Statistics
          </v-btn>

          <span class="ml-4">View the current statistics for the program.</span>

          <v-divider class="my-4" />

          <v-btn
            outlined
            @click="events"
          >
            <v-icon left>
              mdi-calendar-edit
            </v-icon>
            Events
          </v-btn>

          <span class="ml-4">View and remove scheduled feedback events.</span>

          <v-divider class="my-4" />

          <v-btn
            outlined
            @click="transactions"
          >
            <v-icon left>
              mdi-bank
            </v-icon>
            Transactions
          </v-btn>

          <span class="ml-4">View transactions and add or remove beans.</span>

          <v-divider class="my-4" />

          <v-btn
            outlined
            @click="codes"  
          >
            <v-icon left>
              mdi-application-array-outline
            </v-icon>
            Codes
          </v-btn>

          <span class="ml-4">View and reset invitation codes</span>

          <v-divider class="my-4" />

          <v-btn
            outlined
            @click="template"
          >
            <v-icon left>
              mdi-file-table-box-multiple
            </v-icon>
            Template
          </v-btn>

          <span class="ml-4">
            Modify template information, cards, media and measurements.
          </span>
          <v-alert
            class="mt-2"
            dense
            outlined
            type="warning"
            border="left"
          >
            In the prototype, changes to a template effects all programs using it.
          </v-alert>

          <v-divider class="my-4" />

          <form
            method="post"
            action="https://europe-west2-bf-prototype-1.cloudfunctions.net/programDataExport"
            target="_blank"
          >
            <input
              type="hidden"
              name="programId"
              :value="programId"
            >
            <input
              type="hidden"
              name="userIdToken"
              :value="userIdToken"
            >

            <v-btn
              type="submit"
              outlined
              class="mr-4"
              @click="exportData"
            >
              <v-icon left>
                mdi-database-export
              </v-icon>
              Export Data
            </v-btn>
            Download Excel file with program data.
          </form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="4">
      <v-card
        class="pa-3"
        outlined
      >
        <v-switch
          v-model="program.running"
          :label="`Program running`"
        />

        <v-btn
          title="Save"
          :disabled="loading"
          @click="saveProgram"
        >
          <v-icon left>
            mdi-content-save-edit
          </v-icon>
          Save
        </v-btn>

        <div class="caption grey--text mt-6">
          <strong>Note</strong>. If the program has passed last event it will
          automatically stop running.
        </div>
      </v-card>

      <v-card
        class="pa-3 mt-4"
        outlined
      >
        <v-btn
          title="Delete"
          color="red lighten-3"
          :disabled="program.running"
          @click="deleteProgram"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
        <div class="caption grey--text mt-6">
          Only progams which are not running can be deleted.
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import firebase from 'firebase/app';
import { db, functions } from '@/firebase';

const callableDeleteProgram = functions.httpsCallable('callableDeleteProgram');

export default {
  name: 'ProgramEdit',
  components: {

  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      program: {
        running: false,
      },
      userIdToken: false,
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  created() {
    this.$bind('program', db.collection('programs').doc(this.programId), { maxRefDepth: 0 });
  },

  mounted() {
    firebase.auth().currentUser.getIdToken(false).then((idToken) => {
      this.userIdToken = idToken;
    });
  },

  methods: {
    saveProgram() {
      this.$store.commit('setLoading', true);

      delete this.program.templateDocRef; // Do not update this

      db.collection('programs').doc(this.programId).update(this.program).then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
      });
    },
    deleteProgram() {
      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to delete this program?') === false) return;

      console.log('delete program');

      this.$store.commit('setLoading', true);
      this.$router.push('/programs');
      callableDeleteProgram({
        programId: this.programId,
      }).then((result) => {
        console.log(result.data);
        this.$store.commit('setLoading', false);
      });
    },
    events() {
      this.$router.push(`/programs/${this.programId}/events`);
    },

    transactions() {
      this.$router.push(`/programs/${this.programId}/transactions`);
    },

    store() {
      this.$router.push(`/programs/${this.programId}/store`);
    },

    template() {
      this.$router.push(`/${this.program.templateDocRef}`);
    },

    statistics() {
      this.$router.push(`/programs/${this.programId}/statistics`);
    },
    
    codes() {
      this.$router.push(`/programs/${this.programId}/codes`);
    },

  },

};
</script>
