<template>
  <v-container class="ma-0">
    <EventRow
      v-for="event in events"
      :key="event.id"
      :event="event"
      @delete-event="deleteEvent(event.id)"
    />
  </v-container>
</template>

<script>
import { db, functions } from '@/firebase';

import EventRow from '@/components/programs/events/EventRow.vue';

const callableDeleteEvent = functions.httpsCallable('callableDeleteEvent');

export default {
  name: 'ProgramsEvents',
  components: {
    EventRow,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
    maxBeans() {
      if (this.program === null) return 0;
      return this.program.maxBeans;
    },
  },

  created() {
    this.$bind('events', db.collection(`programs/${this.programId}/events`).orderBy('eventAt', 'asc'));
  },

  methods: {
    deleteEvent(eventId) {
      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to delete this event?\n\nGained beans (if any) for the event will be lost and program reward prices might be lowered to maintain the same purchase power in relation to the success ratio.') === false) return;

      this.$store.commit('setLoading', true);
      callableDeleteEvent({
        programId: this.programId,
        eventId,
      }).then((result) => {
        this.$store.commit('setLoading', false);
        console.log(result);
      });
    },

  },

};
</script>
