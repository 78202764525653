<template>
  <span>
    <strong
      class="button"
      :class="{ 'text-decoration-line-through': beanPrice == 0 }"
    >{{ beanPrice }}</strong>
    <v-btn
      class="bean-coin mx-2"
      elevation="1"
      fab
      x-small
      color="yellow"
    >
      <v-img
        src="@/assets/images/bf-logo-square.svg"
        eager
        max-width="23"
      />
    </v-btn>
  </span>
</template>


<script>

export default {
  name: 'BeanPrice',
  props: {
    beanPrice: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
