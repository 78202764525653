<template>
  <v-row no-gutters>
    <v-col
      md="4"
      class="pr-4"
    >
      <v-container
        id="card-list"
        style="max-height: 700px"
        class="overflow-y-auto"
      >
        <CardPreview
          v-for="card in templateCards"
          :key="card.id"
          :template-id="templateId"
          :card-id="card.id"
          :selected="card.id === selCardId"
          class="mb-3"
          @edit-card="editCard"
        />
      </v-container>

      <v-divider class="mt-2 mb-1" />

      <div class="caption grey--text">
        Cards Tags
      </div>
      <v-chip
        v-for="(tag, index) in templateInfo.cardsTags"
        :key="index"
        class="ml-1"
        small
      >
        {{ tag }}
      </v-chip>

      <v-divider class="mt-1 mb-2" />

      <v-card
        class="d-flex justify-space-between"
        flat
      >
        <v-btn
          small
        >
          <v-icon
            left
          >
            mdi-application-import
          </v-icon>
          Import Card
        </v-btn>

        <v-btn
          small
          @click="newCard"
        >
          <v-icon
            left
          >
            mdi-plus
          </v-icon>
          New Card
        </v-btn>
      </v-card>
    </v-col>

    <v-col
      v-if="!cardSelected"
      md="8"
    >
      <div
        class="display-1 grey--text lighten-1 mt-10 text-center"
      >
        Select a card to edit
      </div>
    </v-col>

    <v-col
      v-if="cardSelected"
      md="5"
    >
      <v-text-field
        v-if="!isTargetCard"
        v-model="templateCards[selCardPos].title"
        label="Title"
        counter="50"
        required
        @blur="saveSelectedCard()"
      />

      <CardTextEditor
        :key="templateCards[selCardPos].id"
        :text="templateCards[selCardPos].text"
        @save-text="saveText"
        @update-text="updateText"
      />

      <div class="v-label caption theme--light">
        Card media
      </div>
      <v-card
        class="d-flex flex-wrap pa-2 mb-4"
        flat
        outlined
      >
        <CardMediaPreview
          v-for="media in selCardMedia"
          :key="media.id"
          :media="media"
          :card-id="selCardId"
          @delete-card-media="deleteCardMedia"
        />

        <v-card
          height="100"
          width="100"
          flat
          class="text-center pt-5 ma-1 pa-2"
        >
          <UploadCardMediaDialog
            :template-id="templateId"
            :card-id="selCardId"
            :count="selCardMedia.length"
            @edit-card="editCard()"
          />
        </v-card>
      </v-card>
    </v-col>

    <v-col
      v-if="cardSelected"
      md="3"
      class="pl-4"
    >
      <v-card class="pa-2">
        <div v-if="templateCards[selCardPos].type == 'target'">
          <div class="subtitle-1 grey--text mb-2">
            Target Card
          </div>
          <div class="caption grey--text mb-2">
            The Target Behavor card contains instruction about the target behavior.
            All template must have one target card.
          </div>
        </div>
        <div v-else>
          <div class="subtitle-1 grey--text mb-2">
            Support Card
          </div>
          <div class="caption grey--text mb-2">
            Support Material should contain information which is relevant to the
            success of the program.
          </div>
        </div>
      </v-card>

      <div
        v-if="!isTargetCard"
        class="mt-4"
      >
        <span class="mr-2 overline text-middle">Move card</span>
        <v-btn
          x-small
          class="mx-2"
          @click="moveSelectedCard(-1)"
        >
          Up
        </v-btn>

        <v-btn
          x-small
          class="mx-2"
          @click="moveSelectedCard(1)"
        >
          Down
        </v-btn>
      </div>

      <v-spacer class="my-4" />

      <div v-if="!isTargetCard">
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row>
                <v-col>
                  <h4>
                    Tag
                  </h4>
                </v-col>
                <v-col>
                  <v-chip
                    v-if="!open && templateCards[selCardPos].tag"
                    small
                  >
                    {{ templateCards[selCardPos].tag }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-combobox
                v-model="templateCards[selCardPos].tag"
                :items="templateInfo.cardsTags"
                clearable
                chips
                hide-selected
                persistent-hint
                label="Tag"
                @blur="saveSelectedCard()"
              />

              <div class="caption grey--text mb-2">
                Card tag can be used for users to quickly search certain types of cards.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row>
                <v-col>
                  <h4>
                    Trigger
                  </h4>
                </v-col>
                <v-col>
                  <div
                    v-if="!open && templateCards[selCardPos].trigger"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-alarm-light
                    </v-icon>
                    {{ templateCards[selCardPos].trigger }}
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                v-model="templateCards[selCardPos].trigger"
                class="mt-4"
                :items="triggers"
                dense
                clearable
                label="Type"
                @blur="saveSelectedCard()"
              />

              <v-select
                v-model="templateCards[selCardPos].triggerRoles"
                class="mt-4"
                :items="roles"
                chips
                dense
                multiple
                label="Roles"
                @blur="saveSelectedCard()"
              />
              <div class="caption grey--text mb-2">
                When triggered the following role will receive the card in the stream.
                <br>
                <strong>Note</strong>. When a card is triggered it will be visible for roles
                in the cardlist.
              </div>

              <HelpDialog>
                <div slot="caption">
                  Card triggers
                </div>
                <div slot="text">
                  <br>
                  Triggers are powerful methods to alert roles of changes in the progress
                  or give encouragement.
                </div>
              </HelpDialog>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row>
                <v-col>
                  <h4>
                    Visability
                  </h4>
                </v-col>
                <v-col>
                  <div
                    v-if="!open && templateCards[selCardPos].hiddenRoles.length > 0"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-eye-off
                    </v-icon>
                    from {{ templateCards[selCardPos].hiddenRoles.length }}
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="caption grey--text mb-2">
                Hide a card from certain roles. By default all roles can see the card.
              </div>

              <v-select
                v-model="templateCards[selCardPos].hiddenRoles"
                class="mt-4"
                :items="roles"
                chips
                dense
                multiple
                label="Hidden from roles"
                @blur="saveSelectedCard()"
              />
              <div class="caption grey--text mb-2">
                <strong>Note</strong> that if a card is triggered it will appear in the stream
                even if it is hidden in the normal deck for that role.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col>
                  <h4>
                    Style
                  </h4>
                </v-col>
                <v-col>
                  <v-card
                    v-if="!open && templateCards[selCardPos].style"
                    :style="{
                      backgroundImage: 'linear-gradient(45deg,' + selCardColors.bgColor1 +','+
                        selCardColors.bgColor2 +')',
                    }"
                    height="20"
                    :title="templateCards[selCardPos].style"
                    class="mr-4"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                class="d-flex flex-wrap pa-2 mb-4"
                flat
                outlined
              >
                <v-hover
                  v-for="(style, index) in cardStyles"
                  :key="index"
                  v-slot="{ hover }"
                >
                  <v-card
                    :style="{
                      backgroundImage: (style.bgImage == null) ?
                        'linear-gradient(45deg,' + style.bgColor1 +','+ style.bgColor2 +')' :
                        'url(/static/backgrounds/' + style.bgImage + ')',
                      backgroundSize: (style !== null && style.bgImage != null) ? 'cover' : null,
                    }"
                    width="40"
                    height="40"
                    class="ma-1 cursor-pointer"
                    :title="style.name"
                    :hover="hover"
                    @click.native="selectStyle(index)"
                  />
                </v-hover>
              </v-card>

              <v-btn
                x-small
                @click="removeStyle()"
              >
                Remove Style
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-divider class="my-4" />
      </div>

      <v-card
        class="d-flex flex-column"
        flat
      >
        <v-btn
          v-if="!isTargetCard"
          small
          color="red lighten-3"
          @click="deleteSelectedCard()"
        >
          <v-icon
            left
          >
            mdi-delete
          </v-icon>Delete card
        </v-btn>
        <v-spacer class="my-2" />
        <v-btn
          small
          @click="saveSelectedCard()"
        >
          <v-icon left>
            mdi-content-save-edit
          </v-icon>Save Card
        </v-btn>
      </v-card>

      <div class="mt-2 text-center">
        <small class="grey--text">Card Id {{ templateCards[selCardPos].id }}</small>
      </div>
    </v-col>
  </v-row>
</template>


<script>
import firebase from 'firebase/app';
import 'firebase/storage';

import { db } from '@/firebase';
import CardPreview from '@/components/templates/CardPreview.vue';
import CardMediaPreview from '@/components/templates/CardMediaPreview.vue';
import CardTextEditor from '@/components/templates/CardTextEditor.vue';

import UploadCardMediaDialog from '@/components/templates/UploadCardMediaDialog.vue';
import HelpDialog from '@/components/HelpDialog.vue';

import cardStyles from '@/config/card-styles';

export default {
  name: 'TemplateEditCards',
  components: {
    CardPreview,
    CardMediaPreview,
    CardTextEditor,
    UploadCardMediaDialog,
    HelpDialog,
  },

  props: {
    templateId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      templateInfo: {
        cardsTags: [],
      },
      storageRef: firebase.storage().ref(),
      selCardId: '',
      selCardMedia: [],
      templateCards: [],
      // Card settings
      triggers: ['random'], //  'low engagement', 'low success',
      roles: ['subject', 'supporter', 'observer'],
      cardStyles,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    cardSelected() {
      return this.selCardId !== '';
    },
    selCardPos() {
      return this.templateCards.findIndex((card) => card.id === this.selCardId);
    },
    isTargetCard() {
      return this.templateCards[this.selCardPos].type === 'target';
    },
    selCardColors() {
      const styleName = this.templateCards[this.selCardPos].style;
      if (styleName !== null) {
        const cardStyleIdx = cardStyles.findIndex((style) => style.name === styleName);
        return cardStyles[cardStyleIdx];
      }
      return null;
    },
  },

  created() {
    this.$bind('templateCards', db.collection(`templates/${this.templateId}/cards/`).orderBy('position'));
    this.$bind('templateInfo', db.collection('templates').doc(this.templateId)); // In order to update cards tags
  },

  methods: {
    editCard(cardId) {
      // Select this card for editing
      this.selCardId = cardId; // Firestore Id

      this.$bind('selCardMedia',
        db.collection(`templates/${this.templateId}/cards/${this.selCardId}/media`).orderBy('position'));
    },

    newCard() {
      this.$store.commit('setLoading', true);
      db.collection(`templates/${this.templateId}/cards`).add({
        title: '',
        text: '',
        position: this.templateCards.length,
        tag: null, // Single tag per card
        style: null,
        trigger: null,
        triggerRoles: [],
        hiddenRoles: [],
        type: 'support',
      })
        .then((docRef) => {
          this.dialog = false;
          this.title = '';
          this.selCardId = docRef.id; // Focus edit on this card

          this.$store.commit('setLoading', false);
          this.$store.commit('clearError');

          const el = this.$el.querySelector('#card-list');
          el.scrollTop = el.scrollHeight; // Scroll to bottom
        })
        .catch((error) => {
          console.error('Error adding card:', error);
        });
    },

    selectStyle(styleIdx) {
      this.templateCards[this.selCardPos].style = this.cardStyles[styleIdx].name;
      this.saveSelectedCard();
    },

    removeStyle() {
      this.templateCards[this.selCardPos].style = null;
      this.saveSelectedCard();
    },

    deleteCardMedia(cardId, mediaId) {
      this.$store.commit('setLoading', true);
      db.collection(`templates/${this.templateId}/cards/${cardId}/media/`).doc(mediaId).delete().then(() => {
        this.$store.commit('setLoading', false);
      })
        .catch((error) => {
          console.error('Error deleting card media:', error);
        });
    },

    updateCardsTags() {
      const tagSet = new Set();
      for (let i = 0; i < this.templateCards.length; i += 1) {
        if (this.templateCards[i].tag != null) {
          tagSet.add(this.templateCards[i].tag);
        }
      }
      const cardTags = Array.from(tagSet);
      const cardsTagsData = { cardsTags: cardTags };
      db.collection('templates').doc(this.templateId).update(cardsTagsData).then(() => {
        this.templateInfo.cardsTags = cardTags;
      })
        .catch((error) => {
          console.error('Error saving template card tags:', error);
        });
    },

    deleteSelectedCard() {
      if (this.selCardMedia.length > 0) {
        alert('First delete all card media!');
        return;
      }

      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to delete the selected card?') === false) return;

      this.$store.commit('setLoading', true);
      this.$unbind('selCardMedia');
      const delCardId = this.selCardId;
      this.selCardId = '';
      db.collection(`templates/${this.templateId}/cards`).doc(delCardId).delete().then(() => {
        this.$store.commit('setLoading', false);
      })
        .catch((error) => {
          console.error('Error deleting card:', error);
        });
    },

    saveText() {
      this.saveSelectedCard(); // Text should be the newest version
    },

    updateText(text) {
      this.templateCards[this.selCardPos].text = text;
    },

    saveSelectedCard() {
      this.$store.commit('setLoading', true);
      const cardData = this.templateCards[this.selCardPos];

      // console.log(cardData);

      cardData.trigger = cardData.trigger === undefined ? null : cardData.trigger;
      cardData.tag = cardData.tag === undefined ? null : cardData.tag;

      if ((cardData.trigger !== null && cardData.triggerRoles.length === 0)
      || (cardData.trigger === null && cardData.triggerRoles.length > 0)) {
        this.$store.commit('setLoading', false);
        this.$store.commit('setError', 'Trigger requires a type and at least one role');
        return;
      }

      db.collection(`templates/${this.templateId}/cards`).doc(this.selCardId).update(cardData).then(() => {
        this.updateCardsTags(); // Update template cards tags
        this.$store.commit('setLoading', false);
      })
        .catch((error) => {
          console.error('Error saving template card:', error);
        });
    },

    moveSelectedCard(step) {
      if (step === -1 && this.selCardPos === 1) {
        return; // Can not go above the first card (target card)
      }
      if (step === 1 && this.selCardPos === this.templateCards.length - 1) {
        return; // Card in last pos
      }

      const oldPos = this.selCardPos;
      const newPos = this.selCardPos + step;
      const switchCardId = this.templateCards[newPos].id;

      const batch = db.batch();
      batch.update(db.collection(`templates/${this.templateId}/cards`).doc(this.selCardId), { position: newPos }); // Move selected card
      batch.update(db.collection(`templates/${this.templateId}/cards`).doc(switchCardId), { position: oldPos }); // Move card to selected card pos
      batch.commit();
    },

  },

};

</script>
