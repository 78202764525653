<template>
  <v-container class="ma-0 fill-height">
    <v-row 
      v-if="isBeanfeeStaff(user.email)"
      class="pa-5"
    >
      <v-col cols="4">
        <v-select
          v-model="dateFilter"
          :items="filter"
          style="width: 300px"
          label="Date range"
          @change="getDateRange"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedOrganization"
          :items="availableOrganizations"
          style="width: 300px"
          label="Organizations"
          @change="changeOrganization"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          block
          height="50px"
          :disabled="!startDate || loading"
          @click="generateReport"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row 
      v-else-if="allowedUser(user.email)"
      class="pa-5"
    >
      <v-col cols="4">
        <v-select
          v-model="dateFilter"
          :items="filter"
          style="width: 300px"
          label="Filter"
          @change="getDateRange"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          block
          height="50px"
          :disabled="!startDate || loading"
          @click="generateReport"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-card
        height="100px"
        width="100%"
        style="display: flex; align-items: center; justify-content: center; 
        text-align: center; font-size: 20px;"
      >
        You do not have access. Please contact helgi@beanfee.com to request access.
      </v-card>
    </v-row>

    <v-row v-if="showStats">
      <v-card 
        class="pa-5"
        width="100%"
      >
        <v-row class="pa-3">
          <v-col 
            class="pa-3"
            width="100%"
            style="font-weight: bold"
          >
            <p>
              {{ formatDate(startDate) }} - {{ formatDate(endDate) }}
              <span style="float: right;">Organization: {{ organization }}</span>
            </p>
          </v-col>
        </v-row>
        <div
          v-if="report == null"
          class="body-1 grey--text"
        >
          <v-progress-circular
            class="ma-10"
            size="70"
            width="7"
            color="grey lighten-2"
            indeterminate
          />
          Generating Report
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <FactCard
                title="Running Programs"
                :fact="report.runningProgramsCount"
              />
            </v-col>
            <v-col>
              <FactCard
                title="Finished Programs"
                :fact="report.finishedProgramsCount"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <FactCard
                title="Total Beans"
                :fact="report.gainedBeans"
              />
            </v-col>
            <v-col>
              <FactCard
                title="Spent Beans"
                :fact="report.spentBeans"
              />
            </v-col>
            <v-col>
              <FactCard
                title="Available Beans"
                :fact="report.totalBeans"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <FactCard
                title="Unique Subjects"
                :fact="report.subjectCount"
              />
            </v-col>
            <v-col>
              <FactCard
                title="Unique Supporters"
                :fact="report.supporterCount"
              />
            </v-col>
            <v-col>
              <FactCard
                title="Unique Owners"
                :fact="report.ownerCount"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
import { functions } from '@/firebase';

import firebase from 'firebase/app';

import 'firebase/auth';

import moment from 'moment';

import FactCard from '@/components/programs/FactCard.vue';

import organizationTags from '@/config/organization-tags.js';

const callableCreateReport = functions.httpsCallable('callableCreateReport');

export default {
  name: 'Report',
  components: {
    FactCard,
  },
  props: {
  },
  data() {
    return {
      dashboard: null,
      filter: ['This week', 'Last week', 'Last 30 days', 'Last 90 days', 'This year', 'Last year'],
      startDate: '',
      endDate: '',
      dateFilter: null,
      showStats: false,
      report: null,
      organization: null,
      availableOrganizations: organizationTags,
      selectedOrganization: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.getOrganization();
  },
  methods: {
    getDateRange() {
      if (this.dateFilter === 'This week') {
        this.startDate = moment().startOf('isoWeek').toISOString();
        this.endDate = moment().endOf('isoWeek').toISOString();
      } else if (this.dateFilter === 'Last week') {
        this.startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toISOString();
        this.endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toISOString();
      } else if (this.dateFilter === 'Last 30 days') {
        this.startDate = moment().subtract(30, 'days').startOf('day').toISOString();
        this.endDate = moment().endOf('day').toISOString();
      } else if (this.dateFilter === 'Last 90 days') {
        this.startDate = moment().subtract(90, 'days').startOf('day').toISOString();
        this.endDate = moment().endOf('day').toISOString();
      } else if (this.dateFilter === 'This year') {
        this.startDate = moment().startOf('year').toISOString();
        this.endDate = moment().endOf('year').toISOString();
      } else if (this.dateFilter === 'Last year') {
        this.startDate = moment().subtract(1, 'year').startOf('year').toISOString();
        this.endDate = moment().subtract(1, 'year').endOf('year').toISOString();
      }
    },

    generateReport() {
      this.showStats = true;

      this.report = null;

      this.$store.commit('setLoading', true);

      callableCreateReport({
        startDate: this.startDate,
        endDate: this.endDate,
        organization: this.organization,
      }).then((results) => {
        this.report = results.data;
        this.$store.commit('setLoading', false);
      });
    },

    allowedUser(email) {
      const allowedUsers = [
        'eirikur@beanfee.com', 
        'helgi@beanfee.com',
        'sveinn@base.is',
        'beanfee@beanfee.com',
        'helga@beanfee.com',
        'silja@beanfee.com',
      ];

      return allowedUsers.includes(email);
    },

    isBeanfeeStaff(email) {
      const allowedUsers = [
        'eirikur@beanfee.com', 
        'helgi@beanfee.com',
        'sveinn@base.is',
        'beanfee@beanfee.com',
      ];

      return allowedUsers.includes(email);
    },

    formatDate(date) {
      return moment(date).format('D MMMM, YYYY');
    },

    async getOrganization() {
      try {
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();

        this.organization = idTokenResult.claims.organization;

      } catch (error) {
        console.log(error);
      }
    },

    changeOrganization() {
      this.organization = this.selectedOrganization;
    },
  },
};
</script>
