<template>
  <v-container class="ma-0">
    <form
      class="login"
      @submit.prevent="updatePassword"
    >
      <v-card
        class="mx-auto"
        max-width="400"
      >
        <v-card-text>
          <v-alert
            v-if="error"
            dense
            outlined
            type="error"
            dismissible
          >
            {{ error.message }}
          </v-alert>

          <h1 class="headline grey--text">
            Update password
          </h1>
          <p>Please insert your current and new passwords</p>

          <v-text-field
            v-model="currentPassword"
            type="password"
            label="Current Password"
            required
          />

          <v-text-field
            v-model="newPassword"
            type="password"
            label="New Password"
            required
          />

          <v-text-field
            v-model="newPasswordAgain"
            type="password"
            label="New Password (again)"
            required
          />
        </v-card-text>
        <v-card-actions class="text-right">
          <v-spacer />
          <v-btn
            type="submit"
            :disabled="loading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-container>
</template>

<script>
export default {
  name: 'UpdatePassword',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/');
      }
    },
  },
  methods: {
    updatePassword() {
      if (this.newPassword !== this.newPasswordAgain) {
        this.newPassword = '';
        this.newPasswordAgain = '';
        this.$store.commit('setError', 'New passwords do not match');
      }

      this.$store.dispatch('updatePassword', {
        email: this.user.email,
        password: this.currentPassword,
        newPassword: this.newPassword,
      });
    },
  },
};
</script>
