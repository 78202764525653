var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.card !== null)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-2 pa-2",class:{ 'empty-card': _vm.isEmpty, 'cursor-pointer': !_vm.selected,
              'selected-card': _vm.selected, 'totally-hidden': _vm.isTotallyHidden },style:({
      backgroundImage: (_vm.style !== null) ?
        (_vm.style.bgImage == null) ?
          'linear-gradient(45deg,' + _vm.style.bgColor1 +','+ _vm.style.bgColor2 +')' :
          'url(/static/backgrounds/' + _vm.style.bgImage + ')' : null,
      backgroundSize: (_vm.style !== null && _vm.style.bgImage != null) ? 'cover' : null,
      color: (_vm.style !== null) ? _vm.style.fgColor : null
    }),attrs:{"hover":hover},nativeOn:{"click":function($event){return _vm.selectCard.apply(null, arguments)}}},[(_vm.card.title.length > 0)?_c('div',{staticClass:"text-center mb-2",class:{ 'headline': _vm.card.title.length <= 25, 'subtitle-1': _vm.card.title.length > 25 }},[_vm._v(" "+_vm._s(_vm.card.title)+" ")]):_vm._e(),(_vm.card.text.length > 0)?_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(_vm.compiledMarkdown)}}):_vm._e(),(_vm.card.text.length == 0 && _vm.readyMedia.length == 1)?_c('div',[_c('ImageFromReference',{attrs:{"reference":_vm.readyMedia[0].image.medium,"contain":false,"max-height":303,"overlay-icon":(_vm.readyMedia[0].type === 'video' || _vm.readyMedia[0].type === 'youtube')
          ? 'mdi-play-circle' : ''}}),_c('div',{staticClass:"subtitle-2 ml-3"},[_vm._v(" "+_vm._s(_vm.readyMedia[0].caption)+" ")])],1):_vm._e(),((_vm.readyMedia.length > 0) && (_vm.readyMedia.length > 1 || _vm.card.text.length > 0))?_c('div',[_c('v-divider',{staticClass:"mt-1"}),_c('v-card',{staticClass:"d-flex flex-wrap pa-2 mb-4 transparent-background",attrs:{"flat":""}},_vm._l((_vm.readyMedia),function(media){return _c('ImageFromReference',{key:media.id,staticClass:"mr-1 mb-1",attrs:{"max-height":70,"min-height":70,"max-width":70,"contain":false,"black-background":true,"reference":media.image.small,"overlay-icon":(media.type === 'video' || media.type === 'youtube')
            ? 'mdi-play-circle' : ''}})}),1)],1):_vm._e(),(_vm.isEmpty)?_c('div',{staticClass:"text-center overline grey--text pa-1"},[_vm._v(" Empty card ")]):_vm._e(),(_vm.hasMarking)?_c('v-overlay',{staticClass:"d-flex align-end flex-column justify-space-between",staticStyle:{"right":"-10px","top":"-10px"},attrs:{"absolute":"","opacity":0.0}},[_c('v-card',{staticClass:"pa-1 ma-1",attrs:{"color":"primary","raised":""}},[(_vm.card.type === 'target')?_c('v-icon',{attrs:{"color":"secondary","size":"medium","title":"Target Behavior"}},[_vm._v(" mdi-bullseye-arrow ")]):_vm._e(),(_vm.card.trigger !== null)?_c('v-icon',{attrs:{"color":"secondary","size":"medium","title":"Trigger"}},[_vm._v(" mdi-alarm-light ")]):_vm._e(),(_vm.isHidden && !_vm.isTotallyHidden)?_c('v-icon',{attrs:{"color":"secondary","size":"medium","title":"Hidden"}},[_vm._v(" mdi-eye-off ")]):_vm._e(),(_vm.isTotallyHidden)?_c('v-icon',{attrs:{"color":"grey","size":"medium","title":"Hidden"}},[_vm._v(" mdi-cellphone-off ")]):_vm._e()],1)],1):_vm._e(),(_vm.hasTag)?_c('v-overlay',{staticClass:"d-flex align-end justify-end",staticStyle:{"right":"-5px","bottom":"-5px"},attrs:{"absolute":"","opacity":0.0}},[_c('v-chip',{attrs:{"small":"","color":"primary","text-color":"secondary"}},[_vm._v(" "+_vm._s(_vm.card.tag)+" ")])],1):_vm._e()],1)]}}],null,false,3710703283)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }