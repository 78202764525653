<template>
  <div class="mt-4">
    <vue-simplemde
      ref="markdownEditor"
      v-model="text"
      :configs="configs"
      @blur="saveText()"
    />
  </div>
</template>


<script>

import VueSimplemde from 'vue-simplemde';

export default {
  // https://github.com/sparksuite/simplemde-markdown-editor
  name: 'CardTextEditor',
  components: {
    VueSimplemde,
  },
  props: {
    text: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      textarea: null,
      configs: {
        spellChecker: false,
      },

    };
  },
  watch: {
    text: {
      handler(val, oldVal) {
        this.$emit('update-text', val);
      },
    },
  },
  methods: {

    saveText() {
      setTimeout(() => { this.$emit('save-text'); }, 100); // Give vue-simplemde 100 ms to update text content
    },

  },

};
</script>

<style scoped>
  @import '~simplemde/dist/simplemde.min.css';
</style>
