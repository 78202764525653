export default {
  apiKey: 'AIzaSyCxj9Kz1b8m3LrB1PSUoNugW2BjKmx2wwc',
  authDomain: 'bf-prototype-1.firebaseapp.com',
  databaseURL: 'https://bf-prototype-1.firebaseio.com',
  projectId: 'bf-prototype-1',
  storageBucket: 'bf-prototype-1.appspot.com',
  messagingSenderId: '1064326098760',
  appId: '1:1064326098760:web:d7d18941b266b137a40d7d',
  measurementId: 'G-0P4S999F4C',
};
