<template>
  <v-card
    tile
    class="ma-2 align-end"
    :class="{ 'clickable': clickable }"
    @click.native="selectReward()"
  >
    <ImageFromReference
      v-if="reward.image !== null"
      :max-width="maxWidth"
      :max-height="height"
      :min-height="height"
      :reference="reward.image.small"
      :overlay-text="title"
    />

    <v-card
      v-if="reward.image === null"
      class="grey--text text-center"
      :height="height"
      :width="maxWidth"
      tile
    >
      <v-icon
        x-large
        class="mt-4"
      >
        mdi-texture-box
      </v-icon>
      <v-card-subtitle>
        {{ reward.title }}
      </v-card-subtitle>
    </v-card>
  </v-card>
</template>

<script>

import ImageFromReference from '@/components/ImageFromReference.vue';

export default {
  name: 'RewardSquare',
  components: {
    ImageFromReference,
  },
  props: {
    reward: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 150,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectReward() {
      if (this.clickable) {
        this.$emit('select-reward', this.reward);
      }
    },

  },

};
</script>

<style scoped>
.clickable {
  cursor: pointer;
  border: 2px solid #FFA45B;
}
</style>
