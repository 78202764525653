<template>
  <v-container>
    <v-row>
      <v-col
        md="8"
        offset-md="2"
      >
        <div class="grey--text">
          <p>
            You will be the owner of the program and will have full administrative access.
            <br>
            If you leave a participant blank you will need to relay
            a code to him which you get will get when the program is started.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="2"
      >
        <div class="title">
          Subject
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          <p>
            When the program is started he will get notified.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="2"
        offset-md="4"
      >
        <v-text-field
          v-model="roles.subject.nickname"
          label="Nickname"
        />
      </v-col>

      <v-col
        md="2"
      >
        <v-text-field
          v-if="user.email == 'sveinn@base.is'"
          v-model="roles.subject.email"
          label="Email Address"
        />
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="2"
      >
        <div class="title mt-2">
          Supporter
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text mt-2">
          The Beanfee supporter is the person who provides the feedback to validate
          the behavior goal.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="2"
        offset-md="4"
      >
        <v-text-field
          v-model="roles.supporter.nickname"
          label="Nickname"
        />
      </v-col>

      <v-col
        md="2"
      >
        <v-text-field
          v-if="user.email == 'sveinn@base.is'"
          v-model="roles.supporter.email"
          label="Email Address"
        />
      </v-col>
    </v-row>
    <v-divider />

    <v-row>
      <v-col
        md="2"
        offset-md="2"
      >
        <div class="title mt-2">
          Observer
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text mt-2">
          The passive observer is a optional role. The observer can see the progress
          of the program.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="2"
        offset-md="4"
      >
        <v-text-field
          v-model="roles.observer.nickname"
          label="Nickname"
        />
      </v-col>

      <v-col
        md="2"
      >
        <v-text-field
          v-if="user.email == 'sveinn@base.is'"
          v-model="roles.observer.email"
          label="Email Address"
        />
      </v-col>
    </v-row>

    <v-divider class="my-2" />
  </v-container>
</template>


<script>


export default {
  name: 'ProgramRoles',
  components: {

  },
  data() {
    return {

    };
  },
  computed: {
    roles() {
      return this.$store.getters.roles;
    },
    user() {
      return this.$store.getters.user;
    },

  },

  methods: {
    setRoles() {
      this.$store.dispatch('setRoles', this.roles);
    },
  },

};
</script>
