<template>
  <v-form v-model="valid">
    <v-row>
      <v-col md="6">
        <v-text-field
          v-model="storeItemInfo.title"
          label="Title"
          counter="50"
          required
        />

        <v-textarea
          v-model="storeItemInfo.description"
          label="Description"
          counter="250"
        />

        <v-row>
          <v-col md="8">
            <v-slider
              v-model="storeItemInfo.successPercentage"
              class="mt-6"
              label="Success %"
              thumb-label="always"
              :thumb-size="24"
              :rules="successPercentageRequired"
            />
          </v-col>
          <v-col md="4">
            <BeanPrice :bean-price="beanPrice" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="storeItemInfo.quantity"
              :items="quantityOptions"
              label="Available Quantity"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="storeItemInfo.responsible"
              :items="responsibleOptions"
              label="Responsible"
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <v-btn
          title="Save"
          :disabled="loading || storeItemInfo.beanPrice === 0 | !valid"
          @click="saveInfo"
        >
          <v-icon left>
            mdi-content-save-edit
          </v-icon>
          Save
        </v-btn>
        <v-btn
          class="float-right"
          color="red lighten-3"
          @click="deleteStoreItem()"
        >
          <v-icon
            left
          >
            mdi-delete
          </v-icon>Delete
        </v-btn>
      </v-col>
      <v-col md="6">
        <ImageFromReference
          v-if="storeItemInfo.image !== null"
          :reference="storeItemInfo.image.medium"
        />
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';
import BeanPrice from '@/components/BeanPrice.vue';


export default {
  name: 'RewardEditInfo',
  components: {
    ImageFromReference,
    BeanPrice,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
    storeItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      program: {
        maxBeans: null,
      },

      quantityOptions: [
        { value: -1, text: 'Unlimited' },
        { value: 0, text: 'Unavailable (out of stock)' },
        { value: 1, text: 'One' },
        { value: 2, text: 'Two' },
        { value: 3, text: 'Three' },
        { value: 4, text: 'Four' },
        { value: 5, text: 'Five' },
      ],
      responsibleOptions: [
        { value: 'supporter', text: 'Supporter' },
        { value: 'observer', text: 'Observer' },
      ],

      storeItemInfo: {
        title: '',
        description: '',
        image: null,
        successPercentage: null,
        quantity: null,
        responsible: '',
        notify: null,
        beanPrice: null,
        type: null,
        favorite: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    beanPrice() {
      return Math.round((this.storeItemInfo.successPercentage / 100) * this.program.maxBeans);
    },
  },
  watch: {
    'storeItemInfo.successPercentage': {
      handler(val, oldVal) {
        this.storeItemInfo.beanPrice = this.beanPrice;
      },
    },

  },
  created() {
    this.$bind('program', db.collection('programs').doc(this.programId));
    this.$bind('storeItemInfo', db.collection(`programs/${this.programId}/store`).doc(this.storeItemId));
  },
  methods: {
    saveInfo() {
      this.$store.commit('setLoading', true);

      db.collection(`programs/${this.programId}/store`).doc(this.storeItemId).update(this.storeItemInfo).then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
      });
    },
    deleteStoreItem() {
      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to delete this item from the store?') === false) return;

      this.$store.commit('setLoading', true);

      this.$unbind('storeItemInfo', false);

      db.collection(`programs/${this.programId}/store`).doc(this.storeItemId).delete().then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
        this.$router.push(`/programs/${this.programId}/store`);
      });
    },

  },

};
</script>
