import Vue from 'vue';
import moment from 'moment';


Vue.filter('capitalize', (value) => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('formatRole', (role) => {
  if (role.me === true) return 'Myself';

  if (role.email !== '') return role.email;

  return 'Anonymous';
});

Vue.filter('formatDateDetail', (value) => moment(value).format('DD. MMM., YYYY, HH:mm (ddd)'));

Vue.filter('formatDate', (value) => moment(value).format('D. MMM YYYY'));
