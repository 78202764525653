<template>
  <v-row>
    <v-col md="6">
      <v-text-field
        v-model="templateInfo.title"
        label="Title"
        counter="50"
        required
        @blur="saveInfo()"
      />

      <v-textarea
        v-model="templateInfo.description"
        label="Description"
        hint="This text window is for internal information only and NOT where the subject‘s 
        goals are listed. Goals are listed in the „Cards“ section above."
        counter="250"
        @blur="saveInfo()"
      />

      <v-select
        v-model="templateInfo.language"
        :items="languages"
        label="Language"
      />

      <v-combobox
        v-model="templateInfo.tags"
        :items="tags"
        :rules="tagRequired"
        counter="3"
        chips
        clearable
        multiple
        label="Tags for your template"
      >
        <template #selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="removeTag(item)"
          >
            <strong>{{ item }}</strong>&nbsp;
          </v-chip>
        </template>
      </v-combobox>

      <v-switch
        v-model="templateInfo.public"
        :label="`Public behaviour template`"
      />
      <div class="caption grey--text mb-6">
        If the template is public other people can start a behaviour program based on it.
        <br>
        <strong>Note</strong>. Changes on the template after a program based on it has started
        will not effect the program.
      </div>

      <v-btn
        title="Save"
        :disabled="loading"
        @click="saveInfo"
      >
        <v-icon left>
          mdi-content-save-edit
        </v-icon>
        Save
      </v-btn>
    </v-col>
    <v-col md="6">
      <div
        v-if="!loading && processingUpload"
        class="text-center ma-10"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="grey lighten-2"
          indeterminate
        />

        <div class="grey--text lighten-2 overline mt-2">
          Processing
        </div>
      </div>

      <ImageFromReference
        v-if="templateInfo.image !== null"
        :reference="templateInfo.image.medium"
      />

      <v-spacer class="my-4" />
      <UploadImage
        :upload-filepath="`uploads/users/${user.uid}/templates/${templateId}/`"
        @upload-started="imageUploadStarted()"
        @upload-finished="imageUploadFinished()"
      />
    </v-col>
  </v-row>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';
import UploadImage from '@/components/UploadImage.vue';

import templateTags from '@/config/template-tags.js';

export default {
  name: 'TemplateEditInfo',
  components: {
    ImageFromReference,
    UploadImage,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tagRequired: [
        (v) => v.length > 0 || 'You must specify one tag',
      ],

      tags: templateTags,
      languages: ['icelandic', 'english'],
      processingUpload: false,
      templateInfo: {
        title: '',
        description: '',
        language: '',
        image: null,
        tags: [],
        cardsTags: [],
        public: false,
      },
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    'templateInfo.image': {
      handler(val, oldVal) {
        if (oldVal === null && val !== null) {
          this.processingUpload = false;
        }
      },
    },

  },

  created() {
    this.$bind('templateInfo', db.collection('templates').doc(this.templateId));
  },
  methods: {
    removeTag(tag) {
      this.templateInfo.tags.splice(this.templateInfo.tags.indexOf(tag), 1);
      this.templateInfo.tags = [...this.templateInfo.tags];
    },
    saveInfo() {
      if (this.templateInfo.tags.length === 0) return;

      this.$store.commit('setLoading', true);

      db.collection('templates').doc(this.templateId).update(this.templateInfo).then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
      });
    },

    imageUploadStarted() {
      this.templateInfo.image = null;
    },

    imageUploadFinished() {
      this.processingUpload = true;
    },

  },

};
</script>
