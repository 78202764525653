<template>
  <v-card class="pa-5">
    <div
      v-if="dashboard == null"
      class="body-1 grey--text"
    >
      <v-progress-circular
        class="ma-10"
        size="70"
        width="7"
        color="grey lighten-2"
        indeterminate
      />
      Fetching Dashboard
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <FactCard
            title="Success"
            :fact="`${dashboard.success}%`"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Progress"
            :fact="`${dashboard.progress}%`"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Participation"
            :fact="`${dashboard.participation}%`"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <FactCard
            title="Total Beans"
            :fact="dashboard.totalBeans"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Spent Beans"
            :fact="dashboard.spentBeans"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Available Beans"
            :fact="dashboard.availableBeans"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <FactCard
            title="Purchased Store Items"
            :fact="dashboard.purchasedStoreItems"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Gained Achievements"
            :fact="dashboard.gainedAchievements"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Program Running"
            :fact="dashboard.programRunning"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <FactCard
            title="Subject Success"
            :fact="`${dashboard.subjectSuccess}%`"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Supporter Success"
            :fact="`${dashboard.supporterSuccess}%`"
          />
        </v-col>
        <v-col>
          <FactCard
            title="Current Success"
            :fact="`${dashboard.currentSuccess}%`"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>


<script>
import { functions } from '@/firebase';

import FactCard from '@/components/programs/FactCard.vue';

const callableStatisticsDashboardData = functions.httpsCallable('callableStatisticsDashboardData');

export default {
  name: 'ProgramDashboard',
  components: {
    FactCard,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dashboard: null,

    };
  },
  created() {
    this.$store.commit('setLoading', true);
    callableStatisticsDashboardData({
      programId: this.programId,
    }).then((results) => {
      this.dashboard = results.data;
      this.$store.commit('setLoading', false);
    });
  },
  methods: {

  },

};
</script>
