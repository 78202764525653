<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
      class="pa-4"
    >
      <RewardEditInformation :reward-id="rewardId" />
    </v-card>
  </v-container>
</template>


<script>
import RewardEditInformation from '@/components/rewards/EditInformation.vue';

export default {
  name: 'RewardEdit',
  components: {
    RewardEditInformation,

  },

  props: {
    rewardId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {

    };
  },

};
</script>
