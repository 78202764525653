<template>
  <v-card class="pa-4">
    <div
      v-if="loading == true"
      class="body-1 grey--text"
    >
      <v-progress-circular
        class="ma-10"
        size="70"
        width="7"
        color="grey lighten-2"
        indeterminate
      />
      Loading Chart
    </div>
    <LineChart
      v-if="loading == false"
      :chart-data="datacollection"
      :options="options"
    />
  </v-card>
</template>


<script>
import { functions } from '@/firebase';
import LineChart from '@/components/LineChart.vue';

const callableStatisticsSuccessProgressData = functions.httpsCallable('callableStatisticsSuccessProgressData');

export default {
  name: 'ProgramSuccessProgress',
  components: {
    LineChart,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      options: {
        responsive: false,
        elements: {
          line: {
            tension: 0.2,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              callback(value, index, values) {
                return `${value}%`;
              },
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Feedback Number',
            },
          }],
        },
      },
      datacollection: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.$store.commit('setLoading', true);
    callableStatisticsSuccessProgressData({
      programId: this.programId,
    }).then((result) => {
      this.loading = false;

      this.datacollection.labels = Array.from({ length: result.data.length }, (_, i) => i + 1);

      this.datacollection.datasets.push({
        label: 'Success Progress',
        backgroundColor: '#FFA45B',
        data: result.data,
      });

      this.$store.commit('setLoading', false);
    });
  },

};
</script>

