<template>
  <v-dialog
    v-model="dialog"
    max-width="810"
  >
    <!-- eslint-disable-next-line -->
    <template v-slot:activator="{ on }">
      <v-hover v-slot="{ hover }">
        <v-card
          class="media-preview ma-1"
          height="100"
          width="100"
          :hover="hover"
          @click.native="viewMedia"
        >
          <div
            v-if="processing"
            class="text-center mt-2"
          >
            <v-progress-circular
              v-if="!processingTimeout"
              :size="50"
              :width="5"
              color="grey lighten-2"
              indeterminate
            />

            <v-icon
              v-else
              large
              color="orange darken-2"
            >
              mdi-progress-alert
            </v-icon>

            <div class="grey--text lighten-2 caption mt-2">
              <span v-if="!processingTimeout">Processing</span>
              <span v-else>Process timeout</span>
            </div>
          </div>
          <div v-else>
            <ImageFromReference
              v-if="media.type === 'image'"
              :reference="media.image.small"
              :contain="false"
              :black-background="true"
              :max-height="100"
              :min-height="100"
            />
            <ImageFromReference
              v-if="media.type === 'video'"
              :reference="media.image.small"
              :contain="false"
              :black-background="true"
              :max-height="100"
              :min-height="100"
            />
            <ImageFromReference
              v-if="media.type === 'youtube'"
              :reference="media.image.small"
              :contain="false"
              :black-background="true"
              :max-height="100"
              :min-height="100"
            />
          </div>

          <v-overlay
            v-if="(media.type === 'video' || media.type === 'youtube') && !processing"
            absolute
            :opacity="0.0"
          >
            <v-icon
              large
              color="primary"
            >
              mdi-play-circle
            </v-icon>
          </v-overlay>
        </v-card>
      </v-hover>
    </template>

    <v-card>
      <ImageFromReference
        v-if="media.type === 'image'"
        :reference="media.image.large"
      />

      <VideoFromReference
        v-if="media.type === 'video'"
        :reference="media.video"
      />

      <VideoFromReference
        v-if="media.type === 'youtube'"
        :youtube-id="media.youtubeId"
      />

      <v-card-title v-if="media.title !== null">
        {{ media.title }}
      </v-card-title>

      <v-card-actions>
        <v-btn
          v-if="!processing || processingTimeout"
          small
          color="red lighten-3"
          @click="deleteMedia()"
        >
          <v-icon
            left
          >
            mdi-delete
          </v-icon>Delete
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

import ImageFromReference from '@/components/ImageFromReference.vue';
import VideoFromReference from '@/components/VideoFromReference.vue';

export default {
  name: 'CardMediaPreview',
  components: {
    ImageFromReference,
    VideoFromReference,
  },
  props: {
    media: {
      type: Object,
      default() {
        return { title: '', processedAt: null };
      },
    },
    cardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    processing() {
      return this.media.processedAt === null;
    },
    processingTime() {
      if (this.media.uploadedAt === null) return 0;


      let processedAt;
      if (this.media.processedAt !== null) {
        processedAt = moment(this.media.processedAt.toDate());
      } else {
        processedAt = moment(new Date());
      }

      const uploadedAt = moment(this.media.uploadedAt.toDate());
      const duration = moment.duration(processedAt.diff(uploadedAt));
      return duration.asSeconds();
    },
    processingTimeout() {
      return this.processing && this.processingTime > 600; // Takes more than 10 minutes
    },
  },
  methods: {
    viewMedia() {
      this.dialog = true;
    },
    deleteMedia() {
      this.$emit('delete-card-media', this.cardId, this.media.id);
      this.dialog = false;
    },

  },

};
</script>

<style scoped>
  .media-preview { cursor: pointer; }
</style>
