<template>
  <v-container class="ma-0">
    <v-row class="text-h3">
      Users
    </v-row>
    <v-row class="mx-3 mb-4">
      <v-col>
        <v-text-field
          v-model="ownerEmail"
          label="Owner email"
        />
      </v-col>

      <v-col>
        <v-select
          v-model="selectedOrganization"
          :items="availableTags"
          label="Select Organization"
        />
      </v-col>

      <v-col>
        <v-btn
          block
          :disabled="!selectedOrganization || ownerEmail.length === 0 || loading"
          @click="assignOrganization"
        >
          Assign Organization to User
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import firebase from 'firebase/app';
import { db, functions } from '@/firebase';

import organizationTags from '@/config/organization-tags.js';

const callableAddUserToOrganization = functions.httpsCallable('callableAddUserToOrganization');

export default {
  name: 'UserRowList',
  components: {
  },
  data() {
    return {
      availableTags: organizationTags,
      ownerEmail: '',
      selectedOrganization: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    async assignOrganization() {
      if (this.selectedOrganization && this.ownerEmail.length > 0) {
        try {
          const data = {
            organization: this.selectedOrganization,
            email: this.ownerEmail,
          };

          this.$store.commit('setLoading', true);

          await callableAddUserToOrganization(data);

          this.$store.commit('setLoading', false);
        } catch (error) {
          console.error('Error Updating users organization:', error);
        }
      }
    },
  },
};
</script>
