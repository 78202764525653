<template>
  <v-container class="ma-0">
    <form
      class="login"
      @submit.prevent="login"
    >
      <v-card
        class="mx-auto"
        max-width="400"
      >
        <v-card-text>
          <v-alert
            v-if="error"
            dense
            outlined
            type="error"
            dismissible
          >
            {{ error.message }}
          </v-alert>

          <h1 class="headline grey--text">
            Login
          </h1>
          <p>Welcome to the beanfee platform prototpe</p>

          <v-text-field
            v-model="email"
            label="Email"
            required
            autofocus
          />
          <v-text-field
            v-model="password"
            type="password"
            label="Password"
            required
          />
        </v-card-text>
        <v-card-actions class="text-right">
          <v-spacer />
          <v-btn
            type="submit"
            :disabled="loading"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/');
      }
    },
  },
  methods: {
    login() {
      this.$store.dispatch('login', { email: this.email, password: this.password });
    },
  },
};
</script>
