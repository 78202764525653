import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import shared from './modules/shared';
import program from './modules/program';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    program,
    shared,
  },
});
