<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
      class="pa-4"
    >
      <AchievementEditInformation :achievement-id="achievementId" />
    </v-card>
  </v-container>
</template>


<script>
import AchievementEditInformation from '@/components/achievements/EditInformation.vue';

export default {
  name: 'AchievementEdit',
  components: {
    AchievementEditInformation,

  },

  props: {
    achievementId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {

    };
  },

};
</script>
