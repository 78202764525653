<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
      class="pa-4"
    >
      <ProgramEdit :program-id="programId" />
    </v-card>
  </v-container>
</template>


<script>
import ProgramEdit from '@/components/programs/Edit.vue';

export default {
  name: 'ProgramsEdit',
  components: {
    ProgramEdit,

  },

  props: {
    programId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {

    };
  },

};
</script>
