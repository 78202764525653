<template>
  <v-container class="ma-0">
    <div class="mx-3 mb-4">
      <v-row>
        <AddRewardDialog
          :max-beans="maxBeans"
          @add-reward="addReward"
        />
        <v-btn
          class="ml-4"
          @click="addRewardBundle();"
        >
          Add Bundle
        </v-btn>
      </v-row>
    </div>

    <v-alert
      v-if="rewards.length == 0"
      type="info"
    >
      It is recommended you define at least <strong>one</strong> rewards.
    </v-alert>

    <v-card
      v-for="(reward, index) in rewards"
      :key="reward.id"
    >
      <v-row
        class="mb-4 px-2"
        align="center"
        justify="space-around"
      >
        <v-col>
          <ImageFromReference
            v-if="reward.image !== null"
            :max-height="150"
            :reference="reward.image.small"
          />

          <div
            v-if="reward.image === null"
            class="grey--text text-center"
          >
            <v-icon
              x-large
            >
              mdi-texture-box
            </v-icon>
            <div>No image</div>
          </div>
        </v-col>
        <v-col>
          <v-text-field
            v-model="reward.title"
            label="Title"
            style="width: 350px"
          />
          <v-text-field
            v-model="reward.description"
            label="Description"
            style="width: 350px"
          />
        </v-col>
        <v-col md="3">
          <div class="caption grey--text">
            Bean Price
          </div>
          <BeanPrice :bean-price="beanPrice(reward.successPercentage)" />
          <v-slider
            v-model="reward.successPercentage"
            class="mt-6"
            label="Success %"
            thumb-label="always"
            :thumb-size="24"
            @change="reorderRewards()"
          />
        </v-col>

        <v-col class="text-end mt-1">
          <v-btn
            block
            color="red lighten-3"
            @click="deleteRewards(index)"
          >
            <v-icon
              left
            >
              mdi-delete
            </v-icon>Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>


import AddRewardDialog from '@/components/programs/start/AddRewardDialog.vue';
import ImageFromReference from '@/components/ImageFromReference.vue';
import BeanPrice from '@/components/BeanPrice.vue';

export default {
  name: 'ProgramRewards',
  components: {
    ImageFromReference,
    AddRewardDialog,
    BeanPrice,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    rewards() {
      return this.$store.getters.rewards;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    maxBeans() {
      return this.$store.getters.schedule.length * 10; // 10 Beans for every feedback event
    },
  },

  methods: {
    deleteRewards(index) {
      this.rewards.splice(index, 1); // Remove the rewards from the list
    },
    reorderRewards() {
      this.$store.dispatch('setRewards', this.rewards); // Force it to reorder
    },
    addReward(rewardData) {
      const newRewards = [...this.rewards, ...[rewardData]];
      this.$store.dispatch('setRewards', newRewards);
    },
    beanPrice(successPercentage) {
      return Math.round((successPercentage / 100) * this.maxBeans);
    },
    addRewardBundle() {
      alert('Reward Bundles Not Ready Yet.');
    },
  },


};
</script>


