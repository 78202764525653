<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
      >
        Add an Achievement
      </v-btn>
    </template>


    <v-stepper
      v-model="step"
      vertical
    >
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        Select an achievements
        <small>From you own private achievements or use a public achievement</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card 
          class="d-flex justify-space-between"
        >
          <v-switch
            v-model="showPublic"
            class="ml-4"
            style="width: 50%"
            label="Show Public Achievements"
          />
          <v-text-field
            v-model="searchQuery"
            label="Search"
            style="width: 50%"
            clearable
          />
        </v-card>
        <TagList
          v-if="showPublic"
          class="mx-4"
          :tags="availableTags"
          @selected-tags="selectedTags"
        />
        <v-card class="mt-2">
          <v-container
            id="scroll-target"
            style="height: 400px"
            class="mb-4 overflow-y-auto"
          >
            <v-card
              class="d-flex flex-wrap"
              flat
              tile
            >
              <v-card-text
                v-if="filteredAchievements.length === 0"
                style="height: 350px; font-size: 36px; display: flex; align-items: center; 
                justify-content: center; flex-direction: column;"
              > 
                <div>Nothing Found</div>
                <v-switch
                  v-model="showPublic"
                  label="Show Public Achievements"
                />
              </v-card-text>
              <AchievementSquare
                v-for="(achievementItem, index) in filteredAchievements"
                :key="achievementItem.id + index"
                :achievement="achievementItem"
                :title="achievementItem.title"
                :max-width="150"
                :height="150"
                :clickable="true"
                @select-achievement="selectAchievement"
              />
            </v-card>
          </v-container>
        </v-card>
        <v-card
          class="d-flex justify-space-between"
        >
          <v-btn
            text
            class="mr-2"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-card-text
            style="width: 50%; text-align: right"
          >
            Count: {{ filteredAchievements.length }}
          </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        Configure the achievement
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form v-model="valid">
          <v-card v-if="achievement !== null">
            <v-card-text>
              <v-row align="center">
                <v-col>
                  <AchievementSquare
                    :achievement="achievement"
                    :max-width="150"
                    :height="150"
                  />
                </v-col>
                <v-col>
                  <div class="caption grey--text">
                    Title
                  </div>
                  <div>
                    {{ achievement.title }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-md-8">
                  <v-slider
                    v-model="achievement.successPercentage"
                    class="mt-6"
                    label="Success Percentage"
                    thumb-label="always"
                    :thumb-size="24"
                    :rules="successPercentageRequired"
                  />
                  <div class="caption grey--text">
                    How much success does then subject need in order to get this achievement.
                  </div>
                </v-col>
                <v-col class="col-md-4">
                  <TimesFeedback
                    :success-percentage="achievement.successPercentage"
                    :max-beans="maxBeans"
                    :show-beans="false"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                :disabled="loading || !valid"
                @click="addAchievement"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';

import ImageFromReference from '@/components/ImageFromReference.vue';
import UploadImage from '@/components/UploadImage.vue';
import TimesFeedback from '@/components/TimesFeedback.vue';

import AchievementSquare from '@/components/programs/start/AchievementSquare.vue';
import TagList from '@/components/TagList.vue';

import achievementTags from '@/config/achievement-tags.js';

export default {
  name: 'ProgramAddAchievementDialog',
  components: {
    AchievementSquare,
    TagList,
    TimesFeedback,
  },
  props: {
    maxBeans: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      availableTags: achievementTags,
      tags: achievementTags,
      showPublic: this.$store.getters.showPublicAchievements,
      step: 1,
      valid: false,
      searchQuery: '',
      achievementsCollection: [],
      

      fieldRequired: [
        (v) => !!v || 'Field is required',
      ],

      successPercentageRequired: [
        (v) => v > 0 || 'Success % can not be 0',
      ],

      achievement: null,

    };
  },

  computed: {
    achievements() {
      return this.$store.getters.achievements;
    },
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    filteredAchievements() {
      if (!this.searchQuery) {
        return this.achievementsCollection;
      }

      const query = this.searchQuery.toLowerCase();
      return this.achievementsCollection.filter((achievement) => achievement
        .title.toLowerCase().includes(query));
    },

  },

  watch: {
    dialog: {
      handler(val, oldVal) {
        if (oldVal === false && val === true) {
          this.step = 1;
          this.achievement = null;
        }
      },
    },
    showPublic: {
      immediate: true,
      handler(val, oldVal) {
        const achievements = db.collection('achievements');
        if (val === false) {
          this.$bind('achievementsCollection', achievements.where('ownerId', '==', this.user.uid));
        } else if (val === true) {
          this.$bind('achievementsCollection', achievements.where('public', '==', true)); // No tags selected
        }
        this.$store.commit('setShowPublicAchievements', val);
      },
    },
    tags: {
      handler(val, oldVal) {
        const achievements = db.collection('achievements');
        if (this.tags.length === 0) {
          this.$bind('achievementsCollection', achievements.where('public', '==', true)); // No tags selected
        } else {
          this.$bind('achievementsCollection', achievements
            .where('tags', 'array-contains-any', this.tags).where('public', '==', true));
        }
      },
    },
  },

  methods: {
    addAchievement() {
      const achievementData = JSON.parse(JSON.stringify(this.achievement)); // Deepcopy
      achievementData.id = this.achievement.id; // Append Id

      const newAchievements = [...this.achievements, ...[achievementData]];

      this.$store.dispatch('setAchievements', newAchievements);

      this.dialog = false;
    },

    selectAchievement(value) {
      this.achievement = value;
      this.step = 2;
    },

    selectedTags(tags) {
      this.tags = tags;
    },

  },

};
</script>
