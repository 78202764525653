<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
    >
      <v-tabs>
        <v-tab>
          <v-icon left>
            mdi-book-open
          </v-icon>Information
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-id-card
          </v-icon>Cards
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-chart-areaspline
          </v-icon>Measurements
        </v-tab>

        <v-tab-item class="ma-4">
          <TemplatesEditInformation :template-id="templateId" />
        </v-tab-item>

        <v-tab-item class="ma-4">
          <TemplatesEditCards :template-id="templateId" />
        </v-tab-item>

        <v-tab-item class="ma-4">
          <TemplatesEditMeasurements :template-id="templateId" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>


<script>
import TemplatesEditInformation from '@/components/templates/EditInformation.vue';
import TemplatesEditCards from '@/components/templates/EditCards.vue';
import TemplatesEditMeasurements from '@/components/templates/EditMeasurements.vue';

export default {
  name: 'TemplatesEdit',
  components: {
    TemplatesEditInformation,
    TemplatesEditCards,
    TemplatesEditMeasurements,
  },

  props: {
    templateId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {

    };
  },

};
</script>
