<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      :hover="hover"
    >
      <v-row
        class="mb-4 px-2 cursor-pointer"
        align="center"
        justify="space-around"
        @click="clickStoreItem(storeItem.id)"
      >
        <v-col>
          <ImageFromReference
            v-if="storeItem.image !== null"
            :max-width="200"
            :max-height="200"
            :reference="storeItem.image.small"
          />

          <v-icon
            v-if="storeItem.image === null"
            class="ml-5"
            large
          >
            mdi-image-off-outline
          </v-icon>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Title
          </div>
          <div>{{ storeItem.title }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Description
          </div>
          <div>{{ storeItem.description }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Bean Price
          </div>
          <div>
            <BeanPrice :bean-price="storeItem.beanPrice" />
            <br>
            <span class="caption">
              ({{ storeItem.successPercentage }}% success)
            </span>
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Initial Quantity
          </div>
          <div>
            {{ storeItem.initialQuantity }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Quantity
          </div>
          <div v-if="storeItem.quantity === -1">
            Unlimited
          </div>
          <div v-else>
            {{ storeItem.quantity }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Tags
          </div>
          <v-chip
            v-for="(tag, index) in storeItem.tags"
            :key="index"
            class="ma-1"
          >
            {{ tag | capitalize }}
          </v-chip>
        </v-col>
        <v-col>
          <div class="text-end">
            <v-icon
              v-if="storeItem.favorite"
              color="primary"
            >
              mdi-heart
            </v-icon>
            <v-icon
              v-else
              color="primary"
            >
              mdi-heart-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>


<script>

import ImageFromReference from '@/components/ImageFromReference.vue';
import BeanPrice from '@/components/BeanPrice.vue';

export default {
  name: 'StoreItemRow',
  components: {
    ImageFromReference,
    BeanPrice,
  },
  props: {
    storeItem: {
      type: Object,
      required: true,
    },
  },

  methods: {
    clickStoreItem(storeItemId) {
      this.$emit('click', storeItemId);
    },

  },

};
</script>
