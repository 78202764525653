<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      :hover="hover"
    >
      <v-row
        class="mb-4 px-2 cursor-pointer"
        align="center"
        justify="space-around"
        @click="clickProgram(program.id)"
      >
        <v-col>
          <div class="caption grey--text">
            First Event
          </div>
          <div>
            {{ program.firstEventAt.toDate() | formatDate }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Last Event
          </div>
          <div>
            {{ program.lastEventAt.toDate() | formatDate }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Total Event Count
          </div>
          <div>
            {{ program.eventTotalCount }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Title
          </div>
          <div>
            {{ program.title }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Subject Nickname
          </div>
          <div>
            {{ program.subjectNickname }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Supporter Nickname
          </div>
          <div>
            {{ program.supporterNickname }}
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Program ID
          </div>
          <div class="caption">
            {{ program.id }}
          </div>
        </v-col>
        <v-col>
          <div class="text-end">
            <v-chip
              v-if="program.running"
              color="success"
            >
              Running
            </v-chip>
            <v-chip
              v-else-if="program.deleting"
              color="error"
            >
              Deleting...
            </v-chip>
            <v-chip
              v-else
              color="info"
            >
              Finished
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>


<script>

export default {
  name: 'ProgramRow',
  components: {

  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deleting() {
      return this.program.deleting === true;
    },
  },
  methods: {
    clickProgram(programId) {
      if (this.deleting) return;

      this.$emit('click', programId);
    },


  },

};
</script>
