<template>
  <v-container class="ma-0 fill-height">
    <RoleNoticeDialog
      v-model="dialog"
      @continue="$refs.roles.setRoles(); currentStep = 2"
    />
    <v-card
      height="100%"
      width="100%"
    >
      <v-stepper
        v-model="currentStep"
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="currentStep > 1"
            step="1"
          >
            Roles
          </v-stepper-step>


          <v-divider />

          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
          >
            Schedule
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="currentStep > 3"
            step="3"
          >
            Rewards
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="currentStep > 4"
            step="4"
          >
            Achievements
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="currentStep > 5"
            step="5"
          >
            Settings
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="6">
            Start
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <Roles ref="roles" />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <LoadStatesDialog />
              <v-btn
                color="primary"
                @click="dialog = true"
              >
                Continue
              </v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <Schedule />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <v-btn
                text
                @click="currentStep = 1"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!validSchedule"
                @click="currentStep = 3"
              >
                Continue
              </v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <Rewards />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <v-btn
                text
                @click="currentStep = 2"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                @click="currentStep = 4"
              >
                Continue
              </v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <Achievements />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <v-btn
                text
                @click="currentStep = 3"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                @click="currentStep = 5"
              >
                Continue
              </v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="5">
            <Settings />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <v-btn
                text
                @click="currentStep = 4"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                @click="currentStep = 6"
              >
                Continue
              </v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="6">
            <Overview />

            <v-card
              class="d-flex justify-space-between"
              flat
            >
              <v-btn
                text
                @click="currentStep = 5"
              >
                Back
              </v-btn>  
              <ProgramStartedDialog
                :result="result" 
                @dialogOpened="startProgram()"
              />
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>


<script>

import Roles from '@/components/programs/start/Roles.vue';
import RoleNoticeDialog from '@/components/programs/start/RoleNoticeDialog.vue';
import Schedule from '@/components/programs/start/Schedule.vue';
import Rewards from '@/components/programs/start/Rewards.vue';
import Achievements from '@/components/programs/start/Achievements.vue';
import Settings from '@/components/programs/start/Settings.vue';

import Overview from '@/components/programs/start/Overview.vue';
import ProgramStartedDialog from '@/components/programs/start/ProgramStartedDialog.vue';
import LoadStatesDialog from '@/components/programs/start/LoadStatesDialog.vue';



export default {
  name: 'ProgramStart',
  components: {
    Roles,
    RoleNoticeDialog,
    Schedule,
    Rewards,
    Achievements,
    Settings,
    Overview,
    ProgramStartedDialog,
    LoadStatesDialog,
  },

  props: {
    templateId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      currentStep: 1,
    };
  },

  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    schedule() {
      return this.$store.getters.schedule;
    },
    rewards() {
      return this.$store.getters.rewards;
    },
    validSchedule() {
      return this.schedule.length > 0;
    },
    showLoadState() {
      return this.currentStep === 1;
    },
    result() {
      return this.$store.getters.result;
    },
  },

  created() {
    this.$store.dispatch('setTemplateId', this.templateId);
  },

  methods: {
    startProgram() {
      this.$store.dispatch('startProgram');
    },
    loadState() {
      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to replace current settings with your last used settings?') === false) return;

      this.$store.dispatch('loadState');
    },
  },

};
</script>
