<template>
  <v-card
    class="mx-auto"
  >
    <v-row
      class="mb-4 px-2"
      align="center"
      justify="space-around"
    >
      <v-col>
        <div class="caption grey--text">
          Role
        </div>
        <div>{{ code.role }}</div>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Code
        </div>
        <div>{{ code.code }}</div>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Used
        </div>
        <div>{{ code.used }}</div>
      </v-col>
      <v-col>
        <div
          class="caption grey--text"
        >
          Used at
        </div>
        <div 
          v-if="code.usedAt"
        >
          {{ code.usedAt.toDate() | formatDateDetail }}
        </div>
        <div 
          v-if="!code.usedAt"
        >
          Not used
        </div>
      </v-col>
      
      <v-col class="text-end mt-1">
        <v-btn
          @click="resetCode()"
        >
          <v-icon
            left
          >
            mdi-cached
          </v-icon>Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>


export default {
  name: 'CodeRow',
  components: {

  },
  props: {
    code: {
      type: Object,
      required: true,
    },
  },

  methods: {
    resetCode() {
      this.$emit('reset-code', this.code.code);
      console.log(this.code.code);
    },

  },

};
</script>
