<template>
  <v-card
    v-if="ready"
    flat
    :color="(blackBackground == true ? 'black' : '')"
  >
    <v-img
      :src="url"
      :lazy-src="url"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :min-height="minHeight"
      :contain="contain"
      class="white--text align-end"
    >
      <div
        v-if="overlayText !== ''"
        class="text-center body-2 pa-1"
        style="background-color: rgba(0,0,0,0.35)"
      >
        {{ overlayText }}
      </div>
    </v-img>
    <v-overlay
      v-if="overlayIcon.length > 0"
      absolute
      :opacity="0.1"
    >
      <v-icon
        color="primary"
      >
        {{ overlayIcon }}
      </v-icon>
    </v-overlay>
  </v-card>
</template>


<script>
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  name: 'ImageFromReference',
  props: {
    reference: {
      type: String,
      required: true,
    },
    contain: {
      type: Boolean,
      default: true,
    },
    blackBackground: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
    maxHeight: {
      type: Number,
      default: 800,
    },
    minHeight: {
      type: Number,
      default: null,
    },
    overlayIcon: {
      type: String,
      default: '',
    },
    overlayText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ready: false,
      url: '',
    };
  },
  computed: {

  },
  created() {
    if (this.reference.startsWith('http')) {
      this.url = this.reference; // Reference is an url and not firebase storage object
      this.ready = true;
    } else {
      firebase.storage().ref(this.reference).getDownloadURL().then((url) => {
        this.url = url;
        this.ready = true;
      });
    }
  },
  methods: {

  },

};
</script>
