<template>
  <v-container class="ma-0">
    <CodeRow
      v-for="code in codes"
      :key="code.code"
      :code="code"
      @reset-code="resetCode"
    />
  </v-container>
</template>
  
<script>
import { db, functions } from '@/firebase';
  
import CodeRow from '@/components/programs/codes/CodeRow.vue';
  
const callableResetInviteCode = functions.httpsCallable('callableResetInviteCode');
  
export default {
  name: 'ProgramsCodes',
  components: {
    CodeRow,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      codes: [],
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  
  created() {
    this.$bind('codes', db.collection(`programs/${this.programId}/invitations`));
  },
  
  methods: {
    resetCode(code) {
      /* eslint-disable-next-line */
      if (confirm('Are you sure you want to reset this code?') === false) return;

      this.$store.commit('setLoading', true);
      callableResetInviteCode({
        code,
      }).then((result) => {
        this.$store.commit('setLoading', false);
        console.log(result);
      });
    },
  },
  
};
</script>
  
