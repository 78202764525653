<template>
  <v-form v-model="valid">
    <v-row>
      <v-col md="6">
        <v-text-field
          v-model="achievementInfo.title"
          label="Title"
          counter="50"
          required
          @blur="saveInfo()"
        />


        <v-slider
          v-model="achievementInfo.successPercentage"
          class="mt-6"
          label="Occurs on success %"
          thumb-label="always"
          :thumb-size="24"
          :rules="successPercentageRequired"
          @change="saveInfo()"
        />
        <div class="caption grey--text mb-6">
          Subject achieves this when reached a set success percentage.
          <br><br>
          <strong>Note.</strong> This is the default success percentage and
          can be changed when a program is started using this achievement.
        </div>

        <v-combobox
          v-model="achievementInfo.tags"
          :items="tags"
          :rules="tagRequired"
          counter="3"
          chips
          clearable
          multiple
          label="Achievement Tags"
          @blur="saveInfo()"
        >
          <template #selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="removeTag(item)"
            >
              <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>


        <v-switch
          v-model="achievementInfo.public"
          :label="`Achievement is public`"
        />
        <div class="caption grey--text mb-6">
          Everybody can use public achievements in their own program.
        </div>

        <v-btn
          title="Save"
          :disabled="loading || !valid"
          @click="saveInfo"
        >
          <v-icon left>
            mdi-content-save-edit
          </v-icon>
          Save
        </v-btn>
      </v-col>
      <v-col md="6">
        <div
          v-if="!loading && processingUpload"
          class="text-center ma-10"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="grey lighten-2"
            indeterminate
          />

          <div class="grey--text lighten-2 overline mt-2">
            Processing
          </div>
        </div>

        <ImageFromReference
          v-if="achievementInfo.image !== null"
          :reference="achievementInfo.image.medium"
        />

        <v-spacer class="my-4" />
        <UploadImage
          :upload-filepath="`uploads/users/${user.uid}/achievements/${achievementId}/`"
          @upload-started="imageUploadStarted()"
          @upload-finished="imageUploadFinished()"
        />
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';
import ImageFromReference from '@/components/ImageFromReference.vue';
import UploadImage from '@/components/UploadImage.vue';

import achievementTags from '@/config/achievement-tags.js';

export default {
  name: 'AchievementEditInfo',
  components: {
    ImageFromReference,
    UploadImage,
  },
  props: {
    achievementId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,

      successPercentageRequired: [
        (v) => v > 0 || 'Success % can not be 0',
      ],

      tagRequired: [
        (v) => v.length > 0 || 'You must specify one tag',
      ],

      storageRef: firebase.storage().ref(),
      tags: achievementTags,
      processingUpload: false,
      achievementInfo: {
        title: '',
        successPercentage: 0,
        image: null,
        tags: [],
        public: false,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  watch: {
    'achievementInfo.image': {
      handler(val, oldVal) {
        if (oldVal === null && val !== null) {
          this.processingUpload = false;
        }
        this.saveInfo();
      },
    },
  },

  created() {
    this.$bind('achievementInfo', db.collection('achievements').doc(this.achievementId));
  },
  methods: {
    saveInfo() {
      if (!this.achievementInfo.tags || this.achievementInfo.tags.length === 0) {
        this.achievementInfo.tags = [];
      }
      this.$store.commit('setLoading', true);

      db.collection('achievements').doc(this.achievementId).update(this.achievementInfo).then(() => {
        this.$store.commit('setLoading', false);
        this.$store.commit('clearError');
      });
    },

    removeTag(tag) {
      this.achievementInfo.tags.splice(this.achievementInfo.tags.indexOf(tag), 1);
      this.achievementInfo.tags = [...this.achievementInfo.tags];
    },

    imageUploadStarted() {
      this.achievementInfo.image = null;
    },

    imageUploadFinished() {
      this.processingUpload = true;
    },

  },

};
</script>
