var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4"},[(_vm.result == null)?_c('div',{staticClass:"body-1 grey--text"},[_c('v-progress-circular',{staticClass:"ma-10",attrs:{"size":"70","width":"7","color":"grey lighten-2","indeterminate":""}}),_vm._v(" Fetching Measurements ")],1):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.result.tableHeaders,"items":_vm.result.tableItems,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.eventAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"event-at"},[_vm._v(_vm._s(_vm._f("formatDateDetail")(new Date(item.eventAt))))])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [(item.subject == true)?_c('v-icon',{staticClass:"pa-1 round-icon true-feedback-bg",attrs:{"size":"small","color":"white","title":"Success"}},[_vm._v(" mdi-check ")]):_vm._e(),(item.subject == false)?_c('v-icon',{staticClass:"pa-1 round-icon false-feedback-bg",attrs:{"size":"small","color":"white","title":"Fail"}},[_vm._v(" mdi-close ")]):_vm._e(),(item.subject == null)?_c('v-icon',{staticClass:"pa-1 round-icon missing-feedback-bg",attrs:{"size":"small","color":"white","title":"Missing"}},[_vm._v(" mdi-help ")]):_vm._e()]}},{key:"item.supporter",fn:function(ref){
var item = ref.item;
return [(item.supporter == true)?_c('v-icon',{staticClass:"pa-1 round-icon true-feedback-bg",attrs:{"size":"small","color":"white","title":"Success"}},[_vm._v(" mdi-check ")]):_vm._e(),(item.supporter == false)?_c('v-icon',{staticClass:"pa-1 round-icon false-feedback-bg",attrs:{"size":"small","color":"white","title":"Fail"}},[_vm._v(" mdi-close ")]):_vm._e(),(item.supporter == null)?_c('v-icon',{staticClass:"pa-1 round-icon missing-feedback-bg",attrs:{"size":"small","color":"white","title":"Missing"}},[_vm._v(" mdi-help ")]):_vm._e()]}},{key:"item.picture_0",fn:function(ref){
var item = ref.item;
return [(item.picture_0 !== '')?_c('ImageFromReference',{attrs:{"max-width":150,"max-height":150,"reference":item.picture_0}}):_vm._e()]}},{key:"item.picture_1",fn:function(ref){
var item = ref.item;
return [(item.picture_1 !== '')?_c('ImageFromReference',{attrs:{"max-width":150,"max-height":150,"reference":item.picture_1}}):_vm._e()]}},{key:"item.picture_2",fn:function(ref){
var item = ref.item;
return [(item.picture_2 !== '')?_c('ImageFromReference',{attrs:{"max-width":150,"max-height":150,"reference":item.picture_2}}):_vm._e()]}}],null,true)}),_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"1","outlined":""}},[_c('v-card',{staticClass:"d-flex flex-wrap",attrs:{"flat":""}},_vm._l((_vm.quantitativeMeasurements),function(qm){return _c('SparkLineChart',{key:qm.id,attrs:{"title":qm.title,"data":_vm.dataById(qm.id),"role":qm.role,"data-type":qm.type,"data-style":qm.style}})}),1),_c('div',{staticClass:"caption grey--text text-center"},[_vm._v(" Measurements Trends ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }