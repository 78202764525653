<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      :hover="hover"
    >
      <v-row
        class="mb-4 px-2 cursor-pointer"
        align="center"
        justify="space-around"
        @click="clickAchievement(achievement.id)"
      >
        <v-col>
          <ImageFromReference
            v-if="achievement.image !== null"
            :max-width="200"
            :max-height="200"
            :reference="achievement.image.small"
          />

          <v-icon
            v-if="achievement.image === null"
            class="ml-5"
            large
          >
            mdi-image-off-outline
          </v-icon>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Title
          </div>
          <div>{{ achievement.title }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Tags
          </div>
          <v-chip
            v-for="(tag, index) in achievement.tags"
            :key="index"
            class="ma-1"
          >
            {{ tag | capitalize }}
          </v-chip>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Success %
          </div>
          <div>{{ achievement.successPercentage }}%</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Created
          </div>
          <div v-if="achievement.createdAt">
            {{ achievement.createdAt.toDate() | formatDate }}
          </div>
        </v-col>
        <v-col>
          <div class="text-end">
            <v-chip
              v-if="achievement.public"
              color="success"
            >
              Public
            </v-chip>
            <v-chip
              v-else
              color="warning"
            >
              Private
            </v-chip>

            <v-chip
              v-if="readOnly"
              class="ml-1"
              color="yellow"
            >
              Read Only
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>


<script>

import ImageFromReference from '@/components/ImageFromReference.vue';

export default {
  name: 'AchievementRow',
  components: {
    ImageFromReference,
  },
  props: {
    achievement: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickAchievement(achievementId) {
      if (this.readOnly) {
        alert('Only the owner can edit this item.');
        return;
      }

      this.$emit('click', achievementId);
    },


  },

};
</script>
