<template>
  <v-container class="ma-0">
    <v-card
      class="mx-auto mt-10"
      max-width="800"
      flat
      color="background"
    >
      <v-img
        src="@/assets/images/bf-science.png"
        eager
      />
      <v-card-text>
        <h1 class="mx-4 headline grey--text text-center">
          Beanfee Control Panel
        </h1>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
};
</script>
