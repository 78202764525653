//
// Available card styles
//
export default [
  {
    // Cloudy Knoxville
    name: 'cloudy_knoxville',
    bgColor1: '#fdfbfb',
    bgColor2: '#ebedee',
    fgColor: '#000000',
  },
  {
    //  Kind Steel
    name: 'kind_steel',
    bgColor1: '#e9defa',
    bgColor2: '#fbfcdb',
    fgColor: '#000000',
  },
  {
    // Everlasting Sky
    name: 'everlasting_sky',
    bgColor1: '#fdfcfb',
    bgColor2: '#e2d1c3',
    fgColor: '#000000',
  },
  {
    // Saint Petersburg
    name: 'saint_petersburg',
    bgColor1: '#f5f7fa',
    bgColor2: '#c3cfe2',
    fgColor: '#000000',
  },
  {
    // Heavy Rain
    name: 'heavy_rain',
    bgColor1: '#cfd9df',
    bgColor2: '#e2ebf0',
    fgColor: '#000000',
  },
  {
    //  Soft Grass
    name: 'soft_grass',
    bgColor1: '#c1dfc4',
    bgColor2: '#deecdd',
    fgColor: '#000000',
  },
  {
    // Warm Flame
    name: 'warm_flame',
    bgColor1: '#ff9a9e',
    bgColor2: '#fad0c4',
    fgColor: '#000000',
  },
  {
    // Winter Neva
    name: 'winter_neva',
    bgColor1: '#a1c4fd',
    bgColor2: '#c2e9fb',
    fgColor: '#000000',
  },
  {
    // Plum Plate
    name: 'plum_plate',
    bgColor1: '#667eea',
    bgColor2: '#764ba2',
    fgColor: '#ffffff',
  },
  {
    // Aqua Splash
    name: 'aqua_splash',
    bgColor1: '#13547a',
    bgColor2: '#80d0c7',
    fgColor: '#ffffff',
  },
  {
    // Premium Dark
    name: 'premium_dark',
    bgColor1: '#434343',
    bgColor2: '#000000',
    fgColor: '#ffffff',
  },
  {
    // Solid Dark Blue (BF Logo Background)
    name: 'dark_blue',
    bgColor1: '#223959',
    bgColor2: '#223959',
    fgColor: '#ffa45b',
  },
  //
  // Image backgrounds
  //
  {
    // Pink Fog
    name: 'pink_fog',
    bgImage: 'pink_fog.jpg',
    fgColor: '#000000',
  },
  {
    // Yellow Blur
    name: 'yellow_blur',
    bgImage: 'yellow_blur.jpg',
    fgColor: '#000000',
  },
  {
    // Pink Blur
    name: 'pink_blur',
    bgImage: 'pink_blur.jpg',
    fgColor: '#000000',
  },
  {
    // Sand Blur
    name: 'sand_blur',
    bgImage: 'sand_blur.jpg',
    fgColor: '#000000',
  },
  {
    // Ice
    name: 'ice',
    bgImage: 'ice.jpg',
    fgColor: '#000000',
  },
  {
    // Flowers
    name: 'flowers',
    bgImage: 'flowers.jpg',
    fgColor: '#000000',
  },
  {
    // Grass
    name: 'grass',
    bgImage: 'grass.jpg',
    fgColor: '#ffffff',
  },
  {
    // Party
    name: 'party',
    bgImage: 'party.jpg',
    fgColor: '#37345A',
  },
  {
    // Mountains
    name: 'Mountains',
    bgImage: 'mountains.jpg',
    fgColor: '#ffffff',
  },
  {
    // Stars
    name: 'stars',
    bgImage: 'stars.jpg',
    fgColor: '#ffffff',
  },
  {
    // Blackboard
    name: 'blackboard',
    bgImage: 'blackboard.jpg',
    fgColor: '#ffffff',
  },
  {
    // Galaxy
    name: 'galaxy',
    bgImage: 'galaxy.jpg',
    fgColor: '#ffffff',
  },
];

// https://digitalsynopsis.com/design/beautiful-color-gradients-backgrounds/
// https://webgradients.com/
