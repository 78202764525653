<template>
  <v-card
    class="mx-auto"
  >
    <v-row
      class="mb-4 px-2"
      align="center"
      justify="space-around"
    >
      <v-col>
        <div class="caption grey--text">
          Date
        </div>
        <div>{{ transaction.createdAt.toDate() | formatDateDetail }}</div>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Beans
        </div>
        <div>{{ transaction.beans }}</div>
      </v-col>
      <v-col>
        <div class="caption grey--text">
          Reason
        </div>
        <div>{{ transaction.reason }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>


export default {
  name: 'TransactionRow',
  components: {

  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  methods: {


  },

};
</script>
