<template>
  <div>
    <div class="mx-3 mb-4">
      <v-row>
        <v-dialog
          v-model="dialog"
          max-width="600"
        >
          <template #activator="{ on }">
            <v-btn v-on="on">
              Add a mesurement
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline">
              New mesurement
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="title"
                label="Title"
                counter="32"
                hint="The title as it should appear in the app"
                persistent-hint
                required
                autofocus
              />
              <v-select
                v-model="role"
                :items="['subject', 'supporter']"
                label="Role"
                hint="Role of the measurement provider"
                persistent-hint
                required
              />
              <v-select
                v-model="type"
                :items="['text', 'number', 'picture']"
                label="Type"
                persistent-hint
                hint="Type of measurement"
                required
              />
              <v-select
                v-model="style"
                :items="styleItems"
                label="Style"
                persistent-hint
                hint="Style of measurement"
                clearable
                required
                @change="changeStyle()"
              />
              <v-row v-if="type == 'number'">
                <v-col>
                  <v-text-field
                    v-model="min"
                    type="number"
                    label="Min"
                    hint="Blank means no min"
                    persistent-hint
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="max"
                    type="number"
                    label="Max"
                    hint="Blank means no max"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-checkbox
                v-model="required"
                label="Required for feedback"
                persistent-hint
                hint="Is this measurement required for feedback"
              />
              <v-checkbox
                v-model="remember"
                label="Remember last value"
                persistent-hint
                hint="Remembers the value for this measurements from the last feedback event"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                type="submit"
                :disabled="loading || !valid"
                @click="addMeasurement"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <v-card
      v-for="(measurement, index) in measurements"
      :key="index"
    >
      <v-row
        class="mb-4 px-2"
        align="center"
        justify="space-around"
      >
        <v-col>
          <div class="caption grey--text">
            Title
          </div>
          <div>{{ measurement.title }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Role
          </div>
          <div>{{ measurement.role }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Type
          </div>
          <div>{{ measurement.type }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Style
          </div>
          <div>
            <span v-if="measurement.style === undefined || measurement.style === ''">
              Normal Input
            </span>
            <span v-if="measurement.style === 'smileys'">
              Five Smileys
            </span>
            <span v-if="measurement.style === 'yes_no'">
              Yes / No
            </span>
            <span v-if="measurement.style === 'time'">
              Time picker
            </span>
            <span v-if="measurement.style === 'textarea'">
              Textarea
            </span>
            <span v-if="measurement.style === 'emotions'">
              Emotions picker
            </span>
            <span v-if="measurement.style === 'percentage'">
              Percentage picker
            </span>
            <span v-if="measurement.style === 'difficulty'">
              Difficulty
            </span>
            <span v-if="measurement.style === 'pain_scale'">
              Pain Scale
            </span>
            <span v-if="measurement.style === 'body'">
              Body
            </span>
          </div>
        </v-col>
        <v-col v-if="measurement.type == 'number'">
          <div class="caption grey--text">
            Limit
          </div>
          <div v-if="measurement.min">
            From {{ measurement.min }}
          </div>
          <div v-if="measurement.max">
            To {{ measurement.max }}
          </div>
        </v-col>

        <v-col>
          <div class="caption grey--text">
            Remember
          </div>
          <div>{{ measurement.remember }}</div>
        </v-col>

        <v-col>
          <div class="caption grey--text">
            Required
          </div>
          <div>{{ measurement.required }}</div>
        </v-col>

        <v-spacer class="my-2" />
        <v-col>
          <v-btn
            block
            color="red lighten-3"
            @click="deleteMeasurement(index)"
          >
            <v-icon
              left
            >
              mdi-delete
            </v-icon>Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-alert
      type="info"
    >
      <strong>Advance feature</strong>
      <br>
      You can define some additional mesurements which are collected when the feedback is collected.
      These measurements can be viewed by the supporter and observer.
    </v-alert>
  </div>
</template>

<script>
import { db } from '@/firebase';
import * as cryptoRandomString from 'crypto-random-string';
import * as slugify from 'slugify';

export default {
  name: 'TemplatesEditMeasurements',
  props: {
    templateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Based of html element input https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
      measurements: [],
      title: '',
      type: '',
      role: '',
      style: '',
      required: false,
      remember: false,
      min: '',
      max: '',
      styleItems: ['smileys', 'yes_no', 'time', 'textarea', 'emotions', 'percentage', 'difficulty', 'pain_scale'], // body'
      dialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    valid() {
      return this.title !== '' && this.role !== '' && this.type !== '';
    },
  },

  created() {
    db.collection('templates').doc(this.templateId).get().then((snapshot) => {
      this.measurements = snapshot.data().measurements;
      console.log(this.measurements);
    });
  },

  methods: {
    addMeasurement() {
      this.$store.commit('setLoading', true);
      this.$store.commit('clearError');

      if (this.style === 'smileys' && (this.type !== 'number' || this.min !== '1' || this.max !== '5')) {
        this.$store.commit('setError', 'Smileys style must be of number type with min 1 and max 5');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'yes_no' && (this.type !== 'number' || this.min !== '0' || this.max !== '1')) {
        this.$store.commit('setError', 'Yes / No style must be of number type with min 0 and max 1');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'time' && (this.type !== 'text' || this.min !== '' || this.max !== '')) {
        this.$store.commit('setError', 'Time style must be of text type with no min or max');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'emotions' && (this.type !== 'text' || this.min !== '' || this.max !== '')) {
        this.$store.commit('setError', 'Emotions style must be of text type with no min or max');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'difficulty' && (this.type !== 'number' || this.min !== '1' || this.max !== '5')) {
        this.$store.commit('setError', 'Difficulty style must be of number type with min 1 and max 5');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'percentage' && (this.type !== 'number' || this.min !== '0' || this.max !== '100')) {
        this.$store.commit('setError', 'Percentage style must be of number type with min 0 or max 100');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'pain_scale' && (this.type !== 'number' || this.min !== '0' || this.max !== '10')) {
        this.$store.commit('setError', 'Pain Scale style must be of number type with min 0 and max 10');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.style === 'body' && (this.type !== 'text' || this.min !== '' || this.max !== '')) {
        this.$store.commit('setError', 'Body style must be of text type with no min or max');
        this.$store.commit('setLoading', false);
        return;
      }

      if (this.remember === true && this.type !== 'text') {
        this.$store.commit('setError', 'Remember last value is only for text');
        this.$store.commit('setLoading', false);
        return;
      }


      const measurement = {
        title: this.title,
        id: `${slugify(this.title.substring(0, 12))}-${cryptoRandomString({ length: 4 })}`, // Create a unique field id
        type: this.type,
        style: this.style !== undefined ? this.style : '',
        role: this.role,
        required: this.required,
        remember: this.remember,
      };

      if (this.type === 'number') {
        if (this.min >= this.max) {
          this.$store.commit('setError', 'Min value must be greater than max value');
          this.$store.commit('setLoading', false);
          return;
        }

        if (this.min !== '') measurement.min = this.min;
        if (this.max !== '') measurement.max = this.max;
      }

      if (this.type === 'picture') {
        this.style = '';
        this.min = null;
        this.max = null;
      }

      this.measurements.push(measurement);

      db.collection('templates').doc(this.templateId)
        .set({
          measurements: this.measurements,
        }, { merge: true })
        .then((docRef) => {
          this.dialog = false;

          this.title = '';
          this.type = '';
          this.role = '';
          this.style = '';
          this.required = false;
          this.remember = false;
          this.min = null;
          this.max = null;

          this.$store.commit('setLoading', false);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    },

    changeStyle() {
      if (this.style === 'smileys') {
        this.type = 'number';
        this.min = '1';
        this.max = '5';
      }

      if (this.style === 'yes_no') {
        this.type = 'number';
        this.min = '0';
        this.max = '1';
      }

      if (this.style === 'time') {
        this.type = 'text';
        this.min = '';
        this.max = '';
      }

      if (this.style === 'textarea') {
        this.type = 'text';
        this.min = '';
        this.max = '';
      }

      if (this.style === 'pain_scale') {
        this.type = 'number';
        this.min = '0';
        this.max = '10';
      }


      if (this.style === 'emotions') {
        this.type = 'text';
        this.min = '';
        this.max = '';
      }

      if (this.style === 'difficulty') {
        this.type = 'number';
        this.min = '1';
        this.max = '5';
      }

      if (this.style === 'percentage') {
        this.type = 'number';
        this.min = '0';
        this.max = '100';
      }

      if (this.style === 'body') {
        this.type = 'text';
        this.min = '';
        this.max = '';
      }
    },

    deleteMeasurement(index) {
      this.$store.commit('setLoading', true);

      this.measurements.splice(index, 1);

      db.collection('templates').doc(this.templateId)
        .set({
          measurements: this.measurements,
        }, { merge: true })
        .then((docRef) => {
          this.$store.commit('setLoading', false);
          this.$store.commit('clearError');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    },

  },

};
</script>
