<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        Add Event for {{ date | formatDate }}
      </v-card-title>
      <v-card-text>
        <v-row
          justify="space-around"
        >
          <v-col md="6">
            <v-dialog
              ref="timeDialog"
              v-model="timeMenu"
              :return-value.sync="event.time"
              persistent
              width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="event.time"
                  label="Time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="event.time"
                scrollable
                format="24hr"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="timeMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.timeDialog.save(event.time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels v-model="recurrPanel">
              <v-expansion-panel>
                <v-expansion-panel-header>Recurring dates</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <strong>From</strong> {{ date | formatDate }}
                    </v-col>
                    <v-col>
                      <v-dialog
                        ref="toDateDialog"
                        v-model="toDateMenu"
                        :return-value.sync="toDate"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-model="toDate"
                            label="To Data"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="toDate"
                          scrollable
                        >
                          <v-spacer />

                          <v-btn
                            text
                            color="primary"
                            @click="toDateMenu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="$refs.toDateDialog.save(toDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Mon"
                        value="Mon"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Tue"
                        value="Tue"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Wed"
                        value="Wed"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Thu"
                        value="Thu"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Fri"
                        value="Fri"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Sat"
                        value="Sat"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="selectedWeekdays"
                        label="Sun"
                        value="Sun"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col>
                      <v-btn
                        small
                        @click="ClearRecurrPanel()"
                      >
                        Clear
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row
          justify="space-around"
        >
          <v-col
            md="4"
            offset="2"
          >
            <span class="body-1">Supporter</span>
          </v-col>
          <v-col md="6">
            <v-icon
              left
            >
              mdi-account-supervisor-circle
            </v-icon>
            <span class="body-1">{{ supporter | formatRole }}</span>
          </v-col>
        </v-row>

        <v-row
          justify="space-around"
        >
          <v-col
            md="6"
          >
            <span class="body-1">Provide Feedback</span>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="event.supporterFeedbackAfterMinutes"
              :rules="minutesRules"
              label="Minutes after"
              type="number"
            />
          </v-col>
          <v-col
            md="3"
          >
            <span class="body-1">{{ supporterFeedbackAtTime }}</span>
          </v-col>
        </v-row>

        <v-row
          justify="space-around"
        >
          <v-col
            md="6"
          >
            <v-checkbox
              v-model="event.supporterSendReminder"
              label="Send Reminder"
            />
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="event.supporterReminderBeforeMinutes"
              :rules="minutesRules"
              label="Minutes before"
              type="number"
            />
          </v-col>
          <v-col
            md="3"
          >
            <span class="body-1">{{ supporterReminderAtTime }}</span>
          </v-col>
        </v-row>

        <v-divider />

        <v-row
          justify="space-around"
        >
          <v-col
            md="4"
            offset="2"
          >
            <span class="body-1">Subject</span>
          </v-col>
          <v-col md="6">
            <v-icon
              left
            >
              mdi-account-circle-outline
            </v-icon>
            <span class="body-1">{{ subject | formatRole }}</span>
          </v-col>
        </v-row>

        <v-row
          justify="space-around"
        >
          <v-col
            md="6"
          >
            <span class="body-1">Provide Feedback</span>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="event.subjectFeedbackAfterMinutes"
              :rules="minutesRules"
              label="Minutes after"
              type="number"
            />
          </v-col>
          <v-col
            md="3"
          >
            <span class="body-1">{{ subjectFeedbackAtTime }}</span>
          </v-col>
        </v-row>

        <v-row
          justify="space-around"
        >
          <v-col
            md="6"
          >
            <v-checkbox
              v-model="event.subjectSendReminder"
              label="Send Reminder"
            />
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="event.subjectReminderBeforeMinutes"
              :rules="minutesRules"
              label="Minutes before"
              type="number"
            />
          </v-col>
          <v-col
            md="3"
          >
            <span class="body-1">{{ subjectReminderAtTime }}</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="disableAdd"
          @click="AddEvents()"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import moment from 'moment';
import recur from 'moment-recur';

export default {
  // https://stackoverflow.com/questions/44446559/vuetify-programmatically-showing-dialog

  name: 'AddEventsDialog',

  props: {
    value: Boolean,
    date: { type: String, required: true },
  },

  data() {
    return {
      timeMenu: false,
      toDateMenu: false,
      recurrPanel: [0],

      toDate: null,
      selectedWeekdays: [],

      event: {
        time: null,
        supporterFeedbackAfterMinutes: 0,
        supporterSendReminder: false,
        supporterReminderBeforeMinutes: 10,

        subjectFeedbackAfterMinutes: 0,
        subjectSendReminder: false,
        subjectReminderBeforeMinutes: 10,
      },

      minutesRules: [
        (v) => (parseInt(v, 10) >= 0) || 'Must be 0 or higher',
        (v) => (parseInt(v, 10) < 1440) || 'Must be lower than 24 hours',
      ],

    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    subject() {
      return this.$store.getters.roles.subject;
    },
    supporter() {
      return this.$store.getters.roles.supporter;
    },

    supporterFeedbackAtTime() {
      if (this.event.time === null) return '';

      return this.getMomentDateTime().add(this.event.supporterFeedbackAfterMinutes, 'm').format('HH:mm');
    },

    supporterReminderAtTime() {
      if (this.event.time === null || this.event.supporterSendReminder === false) return '';

      return this.getMomentDateTime().subtract(this.event.supporterReminderBeforeMinutes, 'm').format('HH:mm');
    },

    subjectFeedbackAtTime() {
      if (this.event.time === null) return '';

      return this.getMomentDateTime().add(this.event.subjectFeedbackAfterMinutes, 'm').format('HH:mm');
    },

    subjectReminderAtTime() {
      if (this.event.time === null || this.event.subjectSendReminder === false) return '';

      return this.getMomentDateTime().subtract(this.event.subjectReminderBeforeMinutes, 'm').format('HH:mm');
    },
    disableAdd() {
      return this.event.time === null;
    },
  },

  watch: {
    value: {
      handler(val, oldVal) {
        if (oldVal === false && val === true) {
          // this.event.startTime = null; // Maybe we want to keep this

          // Helgi asked to keep these settings
          // this.toDate = null;
          // this.selectedWeekdays = [];
          // this.recurrPanel = [0];
        }
      },
    },
  },

  methods: {
    getMomentDateTime() {
      return moment(`${this.date}T${this.event.time}:00`);
    },

    AddEvents() {
      const eventData = JSON.parse(JSON.stringify(this.event)); // Deepcopy

      if (this.toDate == null) {
        // Single date
        eventData.name = `${this.event.time}`;
        eventData.start = this.date; // For the schedule component
        eventData.eventAt = this.getMomentDateTime().toISOString();

        this.$emit('add-events', [eventData]);
      } else {
        // Recurring dates
        console.log(`${this.date}-${this.toDate} every ${this.selectedWeekdays} at ${this.event.time}`);

        const recurrence = moment().recur({
          start: this.date,
          end: this.toDate,
        }).every(this.selectedWeekdays).daysOfWeek();
        const recurrenceDates = recurrence.all('YYYY-MM-DD');

        const eventsData = [];
        for (let i = 0; i < recurrenceDates.length; i += 1) {
          const recurrDate = recurrenceDates[i];

          eventsData.push({
            ...eventData,
            name: `${this.event.time}`,
            start: recurrDate,
            eventAt: moment(`${recurrDate}T${this.event.time}:00`).toISOString(),
          });
        }

        this.$emit('add-events', eventsData);
      }

      this.dialog = false; // Close dialog
    },

    ClearRecurrPanel() {
      this.toDateMenu = false;
      this.recurrPanel = [0];
      this.toDate = null;
      this.selectedWeekdays = [];
    },

  },



};
</script>
