<template>
  <v-container class="ma-0">
    <div class="mx-3 mb-4">
      <v-row>
        <AddAchievementsDialog :max-beans="maxBeans" />
        <v-btn
          class="ml-4"
          @click="addAchievementBundle();"
        >
          Add Bundle
        </v-btn>
      </v-row>
    </div>

    <v-alert
      v-if="achievements.length == 0"
      type="info"
    >
      It is recommended you define at least <strong>three</strong> achievements and
      place them unequally on the success percentage scale.
      <br>
      <strong>Note</strong> that the placement of the achievements could be
      automatically moved in a live program if the platform detects low success
      and more encouragement is needed for the subject.
    </v-alert>

    <v-card
      v-for="(achievement, index) in achievements"
      :key="achievement.id"
    >
      <v-row
        class="mb-4 px-2"
        align="center"
        justify="space-around"
      >
        <v-col>
          <ImageFromReference
            v-if="achievement.image !== null"
            :max-height="150"
            :reference="achievement.image.small"
          />

          <div
            v-if="achievement.image === null"
            class="grey--text text-center"
          >
            <v-icon
              x-large
            >
              mdi-texture-box
            </v-icon>
            <div>No image</div>
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Title
          </div>
          <div>{{ achievement.title }}</div>
        </v-col>
        <v-col md="4">
          <v-slider
            v-model="achievement.successPercentage"
            class="mt-6"
            label="Success %"
            thumb-label="always"
            :thumb-size="24"
            @change="reorderAchievement()"
          />
        </v-col>
        <v-col>
          <div class="caption grey--text">
            Min. Feedback Events
          </div>
          {{ timesSuccess(index) }}
        </v-col>

        <v-col class="text-end mt-1">
          <v-btn
            block
            color="red lighten-3"
            @click="deleteAchievement(index)"
          >
            <v-icon
              left
            >
              mdi-delete
            </v-icon>Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>


import AddAchievementsDialog from '@/components/programs/start/AddAchievementsDialog.vue';
import ImageFromReference from '@/components/ImageFromReference.vue';

export default {
  name: 'ProgramAchievements',
  components: {
    ImageFromReference,
    AddAchievementsDialog,
  },
  data() {
    return {
      dialog: false,

    };
  },
  computed: {
    achievements() {
      return this.$store.getters.achievements;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    maxBeans() {
      return this.$store.getters.schedule.length * 10; // 10 Beans for every feedback event
    },
  },

  methods: {
    deleteAchievement(index) {
      this.achievements.splice(index, 1); // Remove the achievement from the list
    },
    timesSuccess(index) {
      const beanPrice = Math.round((this.achievements[index].successPercentage / 100)
      * this.maxBeans);
      return Math.floor(beanPrice / 10);
    },
    reorderAchievement() {
      this.$store.dispatch('setAchievements', this.achievements); // Force it to reorder
    },
    addAchievementBundle() {
      alert('Achievement Bundles Not Ready Yet.');
    },
  },


};
</script>


