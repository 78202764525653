<template>
  <v-container>
    <v-row>
      <v-col
        md="8"
        offset-md="2"
      >
        <div class="grey--text">
          <p>
            The default settings are suitable for most use cases.
            These settings can be modified if needed for for a given program.
          </p>
          <v-btn
            v-if="!shown"
            elevation="1"
            @click="shown = true"
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            Show Settings
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div
      v-if="shown"
    >
      <v-divider class="my-2" />

      <v-row>
        <v-col
          md="2"
          offset-md="1"
        >
          <div class="title">
            Report
          </div>
        </v-col>
        <v-col
          md="6"
        >
          <div class="grey--text">
            Reports Settings
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="2"
        >
          <v-text-field
            v-model="settings.report.email"
            label="Email"
          />
        </v-col>

        <v-col
          md="4"
        >
          <p>
            The system can send automatic mails on various events, such as
            when the low engagement is detected.
          </p>
        </v-col>
      </v-row>

      <v-divider class="my-2" />

      <v-row>
        <v-col
          md="2"
          offset-md="1"
        >
          <div class="title">
            Chat
          </div>
        </v-col>
        <v-col
          md="6"
        >
          <div class="grey--text">
            Program Chat Settings
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="2"
          offset-md="2"
        >
          <v-switch
            v-model="settings.chat.enabled"
            label="Enabled"
          />
        </v-col>

        <v-col
          md="6"
        >
          <p>
            Chat is enabled by default but due to regularly restriction in some fields,
            such as healthcare, the chat can be disabled.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="2"
        >
          <v-text-field
            v-model="settings.chat.banner"
            label="Static Banner in Chat"
          />
        </v-col>

        <v-col
          md="4"
        >
          <p>
            If you want to display a static banner in the chat window for participants.
            Useful for showing disclaimers.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
          offset-md="2"
        >
          <v-text-field
            v-model="settings.chat.link"
            label="3rd Party Chat URL"
          />
        </v-col>

        <v-col
          md="4"
        >
          <p>
            If you want to link participant to a 3rd party chat. Can be a web URL or email.
          </p>
        </v-col>
      </v-row>

      <v-divider class="my-2" />

      <v-row>
        <v-col
          md="2"
          offset-md="1"
        >
          <div class="title">
            Store
          </div>
        </v-col>
        <v-col
          md="6"
        >
          <div class="grey--text">
            Store Settings
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="2"
          offset-md="2"
        >
          <v-switch
            v-model="settings.store.enabled"
            label="Enable"
          />
        </v-col>

        <v-col
          md="6"
        >
          <p>
            The reward store is shown by by default but can be disabled and hidden.
            This is useful when no rewards are defined.
          </p>
        </v-col>
      </v-row>

      <v-divider class="my-2" />

      <v-row>
        <v-col
          md="2"
          offset-md="1"
        >
          <div class="title">
            Feedback
          </div>
        </v-col>
        <v-col
          md="6"
        >
          <div class="grey--text">
            Feedback Settings
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="3"
          offset-md="2"
        >
          <v-switch
            v-model="settings.feedback.supporterAuto"
            label="Supporter Auto Feedback"
          />
        </v-col>

        <v-col
          md="5"
        >
          <p>
            If the supporter does not give feedback on an event within 5 hours,
            the system can automatically give a positive feedback.
            This is useful if the supporter is likely to forget to give feedback.
          </p>
        </v-col>
      </v-row>
    </div>

    <v-divider class="my-2" />
  </v-container>
</template>


<script>


export default {
  name: 'ProgramSettings',
  components: {

  },
  data() {
    return {
      shown: false,
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    user() {
      return this.$store.getters.user;
    },

  },

  methods: {
    setRoles() {
      this.$store.dispatch('setSettings', this.settings);
    },
  },

};
</script>
