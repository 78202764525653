<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
      >
        Add a Reward
      </v-btn>
    </template>


    <v-stepper
      v-model="step"
      vertical
    >
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        Select a reward
        <small>From you own private rewards or use a public reward</small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-card 
          class="d-flex justify-space-between"
        >
          <v-switch
            v-model="showPublic"
            class="ml-4"
            style="width: 50%"
            label="Show Public Rewards"
          />
          <v-text-field
            v-model="searchQuery"
            label="Search"
            style="width: 50%"
            clearable
          />
        </v-card>
        <TagList
          v-if="showPublic"
          class="mx-4"
          :tags="availableTags"
          @selected-tags="selectedTags"
        />
        <v-card class="mt-2">
          <v-container
            id="scroll-target"
            style="height: 400px"
            class="mb-4 overflow-y-auto"
          >
            <v-card
              class="d-flex flex-wrap"
              flat
              tile
            >
              <v-card-text
                v-if="filteredRewards.length === 0"
                style="height: 350px; font-size: 36px; display: flex; align-items: center; 
                justify-content: center; flex-direction: column;"
              > 
                <div>Nothing Found</div>
                <v-switch
                  v-model="showPublic"
                  label="Show Public Rewards"
                />
              </v-card-text>
              <RewardSquare
                v-for="(rewardItem, index) in filteredRewards"
                :key="rewardItem.id + index"
                :reward="rewardItem"
                :title="rewardItem.title"
                :description="rewardItem.description"
                :max-width="150"
                :height="150"
                :clickable="true"
                @select-reward="selectReward"
              />
            </v-card>
          </v-container>
        </v-card>
        <v-card
          class="d-flex justify-space-between"
        >
          <v-btn
            text
            class="mr-2"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-card-text
            style="width: 50%; text-align: right"
          >
            Count: {{ filteredRewards.length }}
          </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        Configure the reward
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form v-model="valid">
          <v-card>
            <v-card-text>
              <v-row align="center">
                <v-col>
                  <RewardSquare
                    v-if="reward !== null"
                    :reward="reward"
                    :max-width="150"
                    :height="150"
                  />
                </v-col>
                <v-col>
                  <div v-if="reward !== null">
                    <v-text-field
                      v-model="reward.title"
                      label="Title"
                    />
                  </div>
                </v-col>
                <v-col>
                  <div v-if="reward !== null">
                    <v-text-field
                      v-model="reward.description"
                      label="Description"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="8">
                  <v-slider
                    v-model="rewardModel.successPercentage"
                    class="mt-6"
                    label="Success %"
                    thumb-label="always"
                    :thumb-size="24"
                    :rules="successPercentageRequired"
                  />
                  <div class="caption grey--text">
                    How much success does then subject need in order to buy this reward once.
                    <br>
                    Once a reward is bought the subject can redeem it at any time.
                  </div>
                </v-col>
                <v-col md="4">
                  <BeanPrice :bean-price="beanPrice" />
                  <TimesFeedback
                    :success-percentage="rewardModel.successPercentage"
                    :max-beans="maxBeans"
                  />
                  <div class="caption grey--text">
                    Bean price is relative to the success and the number of feedback events.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="rewardModel.quantity"
                    :items="quantityOptions"
                    label="Available Quantity"
                    :rules="fieldRequired"
                  />
                  <div class="caption grey--text">
                    How often can the reward be bought by the subject.
                  </div>
                </v-col>
                <v-col>
                  <v-select
                    v-model="rewardModel.responsible"
                    :items="responsibleOptions"
                    label="Responsible"
                    :rules="fieldRequired"
                  />
                  <div class="caption grey--text">
                    Will be notified when the subject wants to redeem the reward.
                  </div>
                </v-col>
                <v-col v-if="running">
                  <v-checkbox
                    v-model="rewardModel.notify"
                    label="Notify Subject"
                  />
                  <div class="caption grey--text">
                    Send subject push notification about this new reward in the store.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                :disabled="loading || !valid"
                @click="addReward"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>


<script>
import firebase from 'firebase/app';

import { db } from '@/firebase';

import ImageFromReference from '@/components/ImageFromReference.vue';
import UploadImage from '@/components/UploadImage.vue';
import BeanPrice from '@/components/BeanPrice.vue';
import TimesFeedback from '@/components/TimesFeedback.vue';

import RewardSquare from '@/components/programs/start/RewardSquare.vue';
import TagList from '@/components/TagList.vue';

import rewardTags from '@/config/reward-tags.js';

export default {
  name: 'ProgramAddRewardDialog',
  components: {
    RewardSquare,
    TagList,
    BeanPrice,
    TimesFeedback,
  },
  props: {
    maxBeans: {
      type: Number,
      default: 0,
    },
    running: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      availableTags: rewardTags,
      tags: rewardTags,
      showPublic: this.$store.getters.showPublicRewards,
      step: 1,
      valid: false,
      searchQuery: '',
      rewardsCollection: [],

      fieldRequired: [
        (v) => !!v || 'Field is required',
      ],

      successPercentageRequired: [
        (v) => v > 0 || 'Success % can not be 0',
      ],

      reward: null,

      rewardModel: {
        successPercentage: null,
        quantity: null,
        responsible: null,
        notify: false,
      },

      quantityOptions: [
        { value: -1, text: 'Unlimited' },
        { value: 1, text: 'One' },
        { value: 2, text: 'Two' },
        { value: 3, text: 'Three' },
        { value: 4, text: 'Four' },
        { value: 5, text: 'Five' },
      ],
      responsibleOptions: [
        { value: 'supporter', text: 'Supporter' },
        { value: 'observer', text: 'Observer' },
      ],

    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    beanPrice() {
      return Math.round((this.rewardModel.successPercentage / 100) * this.maxBeans);
    },
    filteredRewards() {
      if (!this.searchQuery) {
        return this.rewardsCollection;
      }

      const query = this.searchQuery.toLowerCase();
      return this.rewardsCollection.filter((reward) => reward.title.toLowerCase().includes(query) 
      || reward.description.toLowerCase().includes(query));
    },
  },

  watch: {
    dialog: {
      handler(val, oldVal) {
        if (oldVal === false && val === true) {
          this.step = 1;
          this.reward = null;
          // Remember the quantity and responsible from last reward added
          this.rewardModel.successPercentage = null;
          this.rewardModel.notify = this.running;
        }
      },
    },
    showPublic: {
      immediate: true,
      handler(val, oldVal) {
        const rewards = db.collection('rewards');
        if (val === false) {
          this.$bind('rewardsCollection', rewards.where('ownerId', '==', this.user.uid));
        } else if (val === true) {
          this.$bind('rewardsCollection', rewards.where('public', '==', true)); // No tags selected
        }
        this.$store.commit('setShowPublicRewards', val);
      },
    },
    tags: {
      handler(val, oldVal) {
        const rewards = db.collection('rewards');
        if (this.tags.length === 0) {
          this.$bind('rewardsCollection', rewards.where('public', '==', true)); // No tags selected
        } else {
          this.$bind('rewardsCollection', rewards
            .where('tags', 'array-contains-any', this.tags).where('public', '==', true));
        }
      },
    },
  },

  methods: {
    addReward() {
      const rewardData = JSON.parse(JSON.stringify(this.reward)); // Deepcopy

      // Append custom config
      rewardData.id = this.reward.id;
      rewardData.successPercentage = this.rewardModel.successPercentage;
      rewardData.quantity = this.rewardModel.quantity;
      rewardData.responsible = this.rewardModel.responsible;
      rewardData.notify = this.rewardModel.notify;

      this.$emit('add-reward', rewardData); // Parent component handles adding reward

      this.dialog = false;
    },

    selectReward(value) {
      this.reward = value;
      this.step = 2;
    },

    selectedTags(tags) {
      this.tags = tags;
    },

  },

};
</script>
