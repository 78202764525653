<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
      class="pa-4"
    >
      <v-row>
        <v-col md="7">
          <ProgramDashboard :program-id="programId" />
        </v-col>
        <v-col md="5">
          <ProgramSuccessProgress :program-id="programId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <ProgramMeasurements :program-id="programId" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import ProgramDashboard from '@/components/programs/Dashboard.vue';
import ProgramSuccessProgress from '@/components/programs/SuccessProgress.vue';
import ProgramMeasurements from '@/components/programs/Measurements.vue';

export default {
  name: 'ProgramsStatistics',
  components: {
    ProgramDashboard,
    ProgramSuccessProgress,
    ProgramMeasurements,
  },

  props: {
    programId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {

    };
  },

};
</script>
