<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <template #activator="{ on }">
      <v-btn
        x-small
        title="Help"
        v-on="on"
      >
        <v-icon left>
          mdi-help-circle-outline
        </v-icon>Help
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        <slot name="caption" />
      </v-card-title>
      <v-card-text>
        <slot name="text" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  name: 'HelpDialog',

  data() {
    return {
      dialog: false,
    };
  },



};
</script>
