import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    login({ commit }, credentials) {
      commit('setLoading', true);
      commit('clearError');

      firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(
          (user) => {
            console.log(user.user);
            commit('setLoading', false);
            const newUser = {
              uid: user.user.uid,
              name: user.user.displayName,
              email: user.user.email,
              photoUrl: user.user.photoURL,
            };
            // emailVerified, phonenumber, isAnonymous
            commit('setUser', newUser);
          },
        )
        .catch(
          (error) => {
            commit('setLoading', false);
            commit('setError', error);
            console.log(error);
          },
        );
    },
    resetUser({ commit }, user) {
      // User is logged into firebase but needs to be written in the store
      commit('setUser', user);
    },
    updatePassword({ commit }, credentials) {
      commit('setLoading', true);
      commit('clearError');

      firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((user) => {
          firebase.auth().currentUser.updatePassword(credentials.newPassword).then(() => {
            commit('setLoading', false);

            // Reset user to
            const newUser = {
              uid: user.user.uid,
              name: user.user.displayName,
              email: user.user.email,
              photoUrl: user.user.photoURL,
            };
            commit('setUser', newUser);
          }).catch((error) => {
            commit('setLoading', false);
            commit('setError', error);
          });
        })
        .catch(
          (error) => {
            commit('setLoading', false);
            commit('setError', error);
          },
        );

      commit('setLoading', false);
    },
    logout({ commit }) {
      firebase.auth().signOut();
      commit('setUser', null);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.user !== null;
    },
    userInitials(state) {
      console.log(state.user.email);
      if (state.user !== null && state.user.email !== null) {
        return state.user.email.substring(0, 2).toUpperCase();
      }
      return '?';
    },
    isStaff(state) {
      const staff = ['sveinn@base.is', 'beanfee@beanfee.com'];
      return (state.user !== null && staff.includes(state.user.email));
    },
  },
};

// Reference
// https://github.com/aofdev/vue-firebase-auth-vuex/blob/master/src/store/user/index.js
