<template>
  <v-container class="ma-0">
    <div class="mx-3 mb-4">
      <v-row>
        <AddRewardDialog
          :max-beans="maxBeans"
          :running="true"
          @add-reward="addReward"
        />
      </v-row>
    </div>

    <StoreItemRow
      v-for="storeItem in storeItems"
      :key="storeItem.id"
      :store-item="storeItem"
      @click="editStoreItem(storeItem.id)"
    />
  </v-container>
</template>

<script>
import { db } from '@/firebase';

import AddRewardDialog from '@/components/programs/start/AddRewardDialog.vue';
import StoreItemRow from '@/components/programs/store/StoreItemRow.vue';

export default {
  name: 'ProgramsStore',
  components: {
    AddRewardDialog,
    StoreItemRow,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      storeItems: [],
      program: null,
      dialog: false,
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
    maxBeans() {
      if (this.program === null) return 0;
      return this.program.maxBeans;
    },
  },

  created() {
    this.$bind('program', db.collection('programs').doc(this.programId));
    this.$bind('storeItems', db.collection(`programs/${this.programId}/store`));
  },

  methods: {
    editStoreItem(storeItemId) {
      this.$router.push(`/programs/${this.programId}/store/${storeItemId}`);
    },
    addReward(rewardData) {
      // Add reward to the store
      this.$store.commit('setLoading', true);
      this.$store.commit('clearError');

      const storeItem = {
        id: rewardData.id,
        title: rewardData.title,
        description: rewardData.description,
        image: rewardData.image,
        tags: rewardData.tags,
        successPercentage: rewardData.successPercentage,
        quantity: rewardData.quantity,
        responsible: rewardData.responsible,
        notify: rewardData.notify,
        beanPrice: Math.round((rewardData.successPercentage / 100) * this.program.maxBeans),
        type: 'voucher',
        favorite: false,
      };

      if (storeItem.image !== undefined) delete storeItem.image.src; // Remove the src image path

      this.$store.commit('setLoading', false);

      db.collection(`programs/${this.programId}/store`)
        .add(storeItem)
        .then((docRef) => {
          this.$store.commit('setLoading', false);
          this.$store.commit('clearError');
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    },
  },

};
</script>
